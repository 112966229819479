export default {
  pageNotFoundMessage: 'We didnt find the website, is the URL correct?',
  brandingMessage: 'by Code Yellow',
  flowmastersBranding: 'Supported by FlowMasters',
  navisionIntegration: {
    field: {
      company: { label: 'Company' },
      manageEndpointsHeader: { label: 'Endpoints' },
      item: { label: 'Items' },
      customer: { label: 'Customers' },
      jobs: { label: 'Jobs' },
      salesOrder: { label: 'Sales Order' },
      purchaseOrder: { label: 'Purchase Order' },
      productionOrder: { label: 'Production Order' },
    },
    purchaseOrderLine: {
      field: {
        requestedReceiptDate: 'Requested Date',
        promisedReceiptDate: 'Promised Date',
      }
    },
    overview: {
      syncButton: 'Sync'
    }
  },
  nav: {
    main: {
      operations: 'Operations',
      production: 'Supply',
      warehouse: 'Warehouse',
      planning: 'Planning',
      personnel: 'Personnel',
      assets: 'Masterdata',
      plantation: 'Plantation',
      demand: 'Demand',
    },
    operations: {
      progress: 'Work Progress',
      batches: 'Batch History',
      orderHistory: 'Order History',
      workStationSessions: 'Work Station Sessions',
    },
    sales: {
      salesOrders: 'Sales & Projects',
    },
    planning: {
      productionLines: 'Production Areas',
      productionRequests: 'Workload',
      planner: 'Scheduler',
      rampUps: 'Scenario Tool',
      workStations: 'Daily Planning',
      capacity: 'Capacity',
      day: 'Day Overview',
      nest: 'Nesting',
    },
    warehouse: {
      stock: 'Stock',
      inbound: 'Receive Orders',
      warehouses: 'Warehouses',
      purchaseOrder: 'Purchase Orders',
      warehouseTransfer: 'Transfer Orders',
    },
    personnel: {
      users: 'Users',
      operators: 'Operators',
      leaveCalendars: 'Leave Calendars',
      leaveSlots: 'Leave Requests',
    },
    assets: {
      processes: 'Processes',
      articleTypes: 'Article Types',
      exactItems: 'Exact Articles',
      productionLines: 'Production Areas',
      capabilities: 'Skills',
      loadCarriers: 'Loading Carriers',
      processPhases: 'Process Phases',
      dataSources: 'Data Sources',
      globalValues: 'Settings',
      templates: 'Templates',
      globalFiles: 'Special Files',
      integrations: 'Integrations',
      projects: 'Projects',
      factories: 'Factories',
      syncrequest: 'Sync Requests',
      metafields: 'Metafields',
      classifications: 'Article Groups',
      nests: 'Nest Types',
    },
    plantation: {
      plantationLines: 'Plantation Areas',
      scripts: 'Scripts',
      units: 'Units',
      plc: 'Machines'
    },
    account: {
      changelog: 'Changelog',
      clockInTime: 'Clocked in at {{clockInTime}}',
      notClockedIn: 'Not clocked in',
      clockIn: 'Clock In',
      clockOut: 'Clock Out',
      profile: 'Profile',
      logout: 'Logout',
      clockOutModal: {
        title: 'You are still clocked in.',
        message: 'Next to logging out, would you like to clock out as well?',
      },
    },
  },
  syncrequestoverview: {
    field: {
      id: { label: 'ID' },
      order: { label: 'Order' },
      createdAt: { label: 'Created At' },
      performedAt: { label: 'Performed At' },
      failedAt: { label: 'Failed At' },
      message: { label: 'Message' },
      status: { label: 'Status' },
    }
  },
  unit4requestoverview: {
    field: {
      id: { label: 'ID' },
      createdAt: { label: 'Created At' },
      message: { label: 'Message' }

    }
  },
  integrations: {
    field: {
      syncRequest: { label: 'Sync requests' },
      isOpen: { label: 'Show open tasks only' },
      unit4: {
        message: { value: 'Increase stock for item with product code {{productID}} with quantity: {{quantity}} ' }
      },
      navision: {
        message: {
          value: 'Purchase order with id {{orderID}} can be marked as completed',
          buy: 'Purchase order with id "{{orderID}}" and article type "{{itemCode}}" with quantity "{{quantity}}" can be completed',
          sell: 'Sales order with id "{{orderID}}" and article type "{{itemCode}}" with quantity "{{quantity}}" can be marked as completed',

        }
      }
    },
    overview: {
      empty: 'There are no sync requests matching your filters.'
    }
  },
  profile: {
    title: 'Profile',
    changePassword: {
      label: 'Change Password',
      passwordOld: 'Current Password',
      passwordNew: 'New Password',
    },
  },
  unit: {
    overview: {
      title: 'Units'
    },
    field: {
      name: {
        label: 'Name'
      }
    }
  },
  plc: {
    field: {
      name: { label: 'Name' },
      type: { label: 'Type' },
    },
    overview: {
      title: 'Machine overview',
      addButton: 'Machine'
    }
  },
  user: {
    overview: {
      title: 'Users',
      empty: 'No users found.',
      addButton: 'User',
      convertToOperator: 'Convert to operator',
    },
    create: {
      title: 'Add {{fullName}}',
    },
    edit: {
      title: 'Edit {{fullName}}',
      saveBeforeLeaveSlot: 'You must first save this user before you can add leave.',
      tab: {
        info: 'Info',
        availability: 'Availability',
        leaveRequestsHistory: 'Leave requests history',
      },
    },
    field: {
      leaveBalances: { label: 'Leave Overview' },
      avatar: { label: 'Avatar' },
      fullName: { label: 'Name', unknown: 'User' },
      firstName: { label: 'First Name' },
      lastName: { label: 'Last Name' },
      email: { label: 'E-mail' },
      password: { label: 'Password' },
      dateJoined: { label: 'Added' },
      manager: { label: 'Manager' },
      language: { label: 'User Language' },
      badgeId: { label: 'Badge ID', help: 'When filled in, this user can login as an operator using this badge id.' },
      clockInRoundOff: { label: 'Clock in round off (in minutes)' },
      clockOutRoundOff: { label: 'Clock out round off (in minutes)' },
      groups: {
        label: 'Groups',
        value: {
          superuser: 'Super User',
          admin: 'Admin',
          manager: 'Manager',
          planner: 'Planner',
          work_station: 'Work Station',
          hr: 'HR',
        },
        empty: 'No groups joined',
      },
      availability: { label: 'Availability' },
      workSchedules: { label: 'Schedules' },
      leaveCalendars: { label: 'Leave Calendars' },
      extraLeaveBalances: {
        label: 'Remaining balance',
        empty: 'No remaining balance added',
      },
      isLoggedInAsOperator: { label: 'Is Operator' },
      editOpenProductionOrder: {
        label: 'Can Edit Open Production Order',
        value: {
          all: 'All',
          some: 'Article Type Specific',
          none: 'None',
        },
      },
      releaseProductionOrder: {
        label: 'Can Release Production Order',
        value: {
          all: 'All',
          some: 'Article Type Specific',
          none: 'None',
        },
      },
      editReleasedProductionOrder: {
        label: 'Can Edit Released Production Order',
        value: {
          all: 'All',
          some: 'Article Type Specific',
          none: 'None',
        },
      },
    },
    login: {
      title: 'Log in',
      reloginMessage: 'You are logged out. Please login again.',
      forgotPasswordLink: 'Forgot password?',
      errors: {
        invalidCredentials: 'Email or password incorrect',
        unknown: 'Unknown error, status code: {{status}}',
      },
      loginButton: 'Log in',
      backButton: 'Go back',
      workStationStartButton: 'Start Work Station',
      operatorStartButton: ' Log in as Operator',
      or: 'Or',
    },
    passwordForgot: {
      title: 'Forgot Password',
      requestButton: 'Send Email',
      requestedEmailText: 'An email has been send to the correct address.',
    },
    resetPassword: {
      title: 'Password Reset',
      resetButton: 'Reset',
      saveSuccess: 'Password reset successful.',
    },
    activate: {
      title: 'Activate account',
      intro: 'Welcome to Tracy! You just need to set a password to activate your account.',
      activateButton: 'Activate account',
      saveSuccess: 'Your account is activated.',
    },
    changePassword: {
      title: 'Change password',
      field: {
        passwordOld: {
          label: 'Current password',
        },
        passwordNew: {
          label: 'New password',
        },
      },
      saveButton: 'Save',
    },
    account: {
      title: 'Profile',
      logoutButton: 'Log out',
      info: {
        title: 'Info',
      },
      password: {
        title: 'Change password',
      },
      logout: {
        title: 'Log out',
        confirm: 'Are you sure you want to log out?',
      },
    },
  },
  exactItem: {
    overview: {
      title: 'Articles',
      empty: 'No articles found.',
      viewLink: '(bekijken)',
      actions: {
        label: 'Change pairing ({{count}})',
        action: {
          linkArticleTypes_one: 'Link ({{count}})',
          linkArticleTypes_other: 'Link article types ({{count}})',
          unlinkArticleTypes_one: 'Unlink article types ({{count}})',
          unlinkArticleTypes_other: 'Unlink article types ({{count}})',
        },
      },
    },
    edit: {
      title: 'Edit Article',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      classification: { label: 'Group' },
      isMakeItem: { label: 'Make' },
      isBuyItem: { label: 'Buy' },
      name: { label: 'Name' },
      articleType: { label: 'Article Type' },
      componentType: { label: 'Component Type' },
    },
  },
  operator: {
    home: {
      welcome: 'Welcome, {{firstName}}!',
      clockInTime: 'You clocked in at {{clockInTime}}.',
      notClockedIn: 'You are currently not clocked in.',
      availability: 'Availability',
      leaveRequestsHistory: 'Leave requests history',
      scheduled: {
        notScheduled: 'You are not scheduled on any work stations today.',
        title: 'Today you are scheduled on the following work stations:',
        times: '{{startAt}} - {{endAt}}',
      },
    },
    availability: {
      title: 'Availability',
    },
    logoutModal: {
      title: 'Logout',
      content: 'Are you sure you want to log out?',
      logoutButton: 'Logout',
    },
    start: {
      title: 'Log in as Operator',
      startButton: 'Log in',
    },
    overview: {
      title: 'Operators',
      empty: 'No operators found',
      addButton: 'Operator',
    },
    create: {
      title: 'Add {{name}}',
    },
    edit: {
      title: 'Edit {{name}}',
      saveBeforeLeaveSlot: 'You must first save this operator before you can add leave.',
      tab: {
        info: 'Info',
        capabilities: 'Skills',
        availability: 'Availability',
        leaveRequestsHistory: 'Leave requests history',
      },
      workScheduleDescription: 'Schedule from {{startDate}}',
    },
    field: {
      leaveBalances: { label: 'Leave Overview' },
      avatar: { label: 'Avatar' },
      fullName: { label: 'Name', unknown: 'Operator' },
      firstName: { label: 'First Name' },
      lastName: { label: 'Last Name' },
      operatorCapabilities: {
        label: 'Skills',
        empty: 'No skills added',
        none: 'No skills configured in the system.',
      },
      createdAt: { label: 'Added' },
      badgeId: { label: 'Badge ID' },
      workSchedules: {
        label: 'Schedules',
        empty: 'No schedules added',
      },
      availability: { label: 'Availability' },
      leaveCalendars: { label: 'Leave Calendars' },
      manager: { label: 'Manager' },
      hasPendingLeave: { label: 'Pending Leave Requests' },
      extraLeaveBalances: {
        label: 'Remaining balance',
        empty: 'No remaining balance added',
      },
      deleted: { label: 'Deleted' },
    },
  },
  articleTypeWarehouse: {
    overview: {
      empty: 'No stock found',
    },
    field: {
      stock: { label: 'Stock', },
    },
    articleType: {
      id: 'ID',
      code: 'Code',
      classification: 'Group',
      name: 'Name',
    },
    warehouse: {
      name: 'Warehouse',
    },
    storageLocations: {
      field: {
        id: { label: 'ID' },
        lines: { label: 'Storagelocations' },
        code: { label: 'Code' },
        default: { label: 'Default' },
        name: { label: 'Name' },
        stock: { label: 'Stock' },
        deleted: { label: 'Deleted?' },
      },
      noStorageLocation: 'No storage location specified.',
    },
  },
  salesOrder: {
    basicInfo: 'Details',
    overview: {
      title: 'Sales Orders',
      empty: 'No Sales Orders found.',
      responsibilityModal: {
        title: {
          edit: 'Edit Responsibility',
          add: 'Add Responsibility',
        },
      },
      allocated: {
        label: 'Allocated',
        value: 'Allocated: {{count}}/{{total}}',
      },
      ready: {
        label: 'Allocated Order(s)',
        value: 'Allocated Order(s): {{count}}/{{total}}',
      },
      currentStock: {
        label: 'Current Stock',
        value: 'Current Stock: {{count}}/{{total}}',
      },
      planned: {
        label: 'Planned',
        value: 'Planned: {{count}}/{{total}}',
      },
      picked: {
        label: 'Picked',
        value: 'Picked: {{count}}/{{total}}',
      },
      shipped: {
        label: 'Shipped',
        value: 'Shipped: {{count}}/{{total}}',
        noCarrierInformation: 'No carrier information available',
        reprintAll: 'Reprint all shipping labels',
        reprint: 'Reprint shipping label',
      },
      delivered: {
        label: 'ERP Delivered',
        value: 'Delivered: {{status}}',
      },
      invoiced: {
        label: 'ERP Invoiced',
        value: 'Invoiced: {{status}}',
      },
      exactReturned: {
        label: 'Returned',
        value: 'Returned: {{count}}/{{total}}',
      },
      batchTable: {
        hidden_one: '{{count}} line hidden',
        hidden_other: '{{count}} lines hidden',
      },
      showMore_one: 'Show more ({{count}} line)',
      showMore_other: 'Show more ({{count}} lines)',
      showLess: 'Show less',
      quantities: {
        projected: '# projected',
        planned: '# planned',
        warehouse: '# warehouse',
        required: '# required',
        allocated: '# allocated',
        expected: '# expected',
      },
      amountDelivered: 'Amount delivered',
      autoAllocateButton: 'Allocate from Current Stock',
      autoUnallocateButton: 'Unallocate from Current Stock',
      unallocatedDeficit: {
        message: 'There is less current stock than is allocated for this article type warehouse combination. Some current stock has to be unallocated in order to continue with the affected orders.',
      },
      allocateAll: 'Allocate all salesOrderLines',
      createPickOrders: 'Create pickorders',
    },
    edit: {
      title: 'Edit Sales Order',
      allocateButton: 'Allocate',
      stage: {
        progress: { label: 'Progress' },
        general: { label: 'General' },
        customer: { label: 'Customer' },
        dates: { label: 'Dates' },
        salesOrderLines: { label: 'Sales Order Lines' },
        pickOrders: { label: 'Pick Orders' },
        deliveryAddress: { label: 'Delivery Address' },
        invoiceAddress: { label: 'Invoice Address' },
        remarks: { label: 'Internal Notes' },
        responsibilities: { label: 'Responsibilities' },
      },
      allocateModal: {
        title: 'Allocate {{articleType}} ({{allocated}}/{{required}}) ',
        allocate: 'Allocate',
        source: {
          quantity: 'Quantity',
          unallocated: 'Available',
          allocated: 'Already Allocated',
          date: 'Due Date',
          quantityStatus: {
            label: 'Status',
            value: {
              open: 'Open',
              in_progress: 'In Progress',
              done: 'Done',
            },
          },
          hasAvailable: { label: 'Available' },
          hasAllocated: { label: 'Allocated' },
        },
      },
      allocatedFromStock: 'Allocated {{count}} from stock.',
      allocatedFromStockAssembly: 'Allocated {{count}} components from stock.',
    },
    confirmDelete: 'Are you sure you want to delete this Sales Order?',
    field: {
      id: { label: 'ID' },
      erpId: { label: 'ERP ID' },
      status: {
        label: 'Status',
        value: {
          no_stock: 'Open',
          open: 'Open',
          partial: 'Partial',
          complete: 'Completed',
          canceled: 'Canceled',
        },
      },
      information: { label: 'Information' },
      customer: { label: 'Customer' },
      reference: { label: 'Reference' },
      creatorFullName: { label: 'Creator' },
      orderNumberErp: { label: 'Number' },
      orderDate: { label: 'Order date' },
      deliveryDate: { label: 'Delivery date' },
      deliveryAddress: { label: 'Delivery address' },
      remarks: { label: 'Remarks' },
      description: { label: 'Description' },
      outShipments: {
        label: 'Pick Orders',
        empty: 'No Pick Orders added yet.',
        value: 'Pick Order {{id}}',
      },
      lines: { label: 'Sales Order Lines' },
      quantity: { label: 'Quantity' },
      exactSalesOrder: { label: 'Sales Order' },
      warehouse: { label: 'Warehouse' },
      allocationStatus: {
        label: 'Status',
        value: {
          unallocated: 'Unallocated (completely or partially)',
          not_ready: 'All allocated but not ready',
          not_shipped: 'All ready but not shipped',
          all_shipped: 'All Shipped',
        },
      },
      progressStatus: {
        values: {
          no_stock: 'No stock {{statusHead}}',
          partial: 'Partial stock {{statusHead}}',
          complete: 'Complete stock {{statusHead}}',
        },
        stockStatus: {
          label: 'Stock Status',
        },
        stockStatusAllocation: {
          label: 'Allocation Status',
        },
        allocationOrderStatus: {
          label: 'Allocation Order Status',
        },
        plannedStatus: {
          label: 'Planned Status',
        },
        pickedStatus: {
          label: 'Picked Status',
        },
        shippedStatus: {
          label: 'Shipped Status',
        },
        deliveryStatus: {
          label: 'Delivered Status',
        },
        invoiceStatus: {
          label: 'Invoiced Status',
        },
      },
      priority: {
        label: 'Priority',
        value: {
          high: '1 - High',
          medium: '2 - Medium',
          low: '3 - Low',
        },
      },
      responsibilities: { empty: 'No responsibilities added yet.' },
      shippingMethod: { label: 'Shipping Method' },
      mostRecentlyPickedDate: { label: 'Picked Date' },
    },
    filters: {
      todo: 'Todo',
      inProgress: 'In Progress',
      complete: 'Complete',
      overdueReceipts: 'Overdue (without work orders)',
      overdueRequests: 'Overdue (with work orders)',
      receiptsThisWeek: 'Planned this week',
      futureReceipts: 'Planned future',
      deleted: 'Deleted',
    },
  },
  salesOrderLine: {
    addToOutShipmentButton: 'Add',
    consumedLine: 'No more available quantity for the selected sales order line(s).',
    field: {
      id: { label: 'ID' },
      number: { label: 'Line' },
      project: { label: 'Project' },
      productionDueDate: { label: 'Order end date' },
      deliveryDate: { label: 'Delivery date' },
      quantity: { label: 'Quantity' },
      _toReceive: { label: 'Te receive' },
      unitPrice: { label: 'Price per piece' },
      netPrice: { label: 'Net Price' },
      salesOrder: { label: 'Sales Order' },
      code: { label: 'Article Code' },
      erpLines: { label: 'ERP Shipped' },
      description: { label: 'Description' },
      allocated: { label: 'Allocated' },
      deliveryStatus: { label: 'Delivery Status' },
    },
  },
  exactPurchaseOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Number' },
      status: {
        label: 'Status',
        value: {
          10: 'Open',
          20: 'Partial',
          30: 'Completed',
          40: 'Canceled',
        },
      },
    },
  },
  purchaseOrder: {
    overview: {
      empty: 'No Purchase Orders found.',
      erpNumber: 'Purchase Order Number',
    },
    confirmDelete: 'Are you sure you want to delete this Purchase Order?',
    filters: {
      status: 'Status',
      requestStatus: 'Work Order Status',
      ordersDueAndExpected: 'Orders due & expected',
      number: 'Number',
      todo: 'Todo',
      inProgress: 'In Progress',
      complete: 'Complete',
      overdueReceipts: 'Overdue (without work orders)',
      overdueRequests: 'Overdue (with work orders)',
      receiptsThisWeek: 'Planned this week',
      futureReceipts: 'Planned future',
      deleted: 'Deleted',
    },
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          partial: 'Partial',
          complete: 'Complete',
          canceled: 'Canceled',
        },
      },
      source: { label: 'Source' },
      supplier: { label: 'Supplier' },
      quantity: { label: 'Quantity' },
      reference: { label: 'Reference' },
      orderNumberErp: { label: 'Number' },
      orderDate: { label: 'Order Date' },
      receiptDate: { label: 'Receive Date', error: 'Receive Date cannot be empty.' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'License plate' },
      lines: { label: 'Purchase Order Lines' },
      inShipments: {
        label: 'Receive Orders',
        empty: 'No Receive Orders added yet.',
        value: 'Receive Order {{id}}',
      },
      exactPurchaseOrder: { label: 'Purchase Order' },
      warehouse: { label: 'Warehouse' },
      linkedSalesOrder: { label: 'Linked Sales Order ERP' },
      shippingMethod: { label: 'Shipping Method' },
      linkedProductionOrder: { label: 'Linked Production Order ERP' },
    },
  },
  purchaseOrderLine: {
    noBatchTypeBuy: 'No batch type `buy` defined for this article',
    consumedLine: 'No more available quantity for the selected purchase order line(s).',
    addToInShipmentButton: 'Add',
    field: {
      id: { label: 'ID' },
      number: { label: 'Line' },
      project: { label: 'Project' },
      productionRequest: { label: 'Work Order' },
      receiptDate: { label: 'Receive Date' },
      quantity: { label: 'Quantity' },
      code: { label: 'Article Code' },
      _toReceive: { label: 'To Receive' },
      unitPrice: { label: 'Unit Price' },
      netPrice: { label: 'Net Price' },
      purchaseOrder: { label: 'Purchase Order' },
      purchaseUnit: { label: 'Ordering Unit' },
      erpLines: { label: 'ERP Received' },
      description: { label: 'Description' },
      allocatedTo: { label: 'Allocated to' },
      linkedTo: { label: 'Linked to' },
      line: {
        label: 'Line',
        tooltip: 'Linked Purchase Order Line',
      },
    },
  },
  inShipment: {
    disabledDelete: 'Cannot delete Receive Order because there are related Work Orders.',
    field: {
      id: { label: 'ID' },
      receivedAt: { label: 'Receive date' },
      trackingCode: { label: 'Tracking Code' },
      licensePlate: { label: 'License plate' },
      shipperName: { label: 'Driver' },
    },
    edit: {
      title: 'Edit Receive Order',
    },
    missingReceiveProcessCreate: 'Create missing Receive Order process',
  },
  inShipmentLine: {
    disabledDelete: 'Cannot delete line because Work Order is in progress or completed',
    field: {
      id: { label: 'ID' },
      autoPerformedProductionRequest: { info: 'This line was added automatically because more articles were received than specified in the Purchase Order.' },
      inShipment: { label: 'Receive ID' },
    },
    create: {
      title: 'Create Receive Order Line',
    },
    edit: {
      title: 'Edit Receive Order Line',
    },
    missingBuyProcessWarning: 'No Inbound process found for this article type.',
    missingBuyProcessCreate: 'Create missing Inbound process',
  },
  outShipment: {
    disabledDelete: 'Cannot delete Pick Order because there are related Work Orders',
    autoMoveStartAt: 'The start date of the main pick order has been changed because you have changed the start date of one of the pick order lines.',
    field: {
      id: { label: 'ID' },
      shipperPickupDate: { label: 'Pickup Date' },
      shipperName: { label: 'Driver' },
      shipperLicensePlate: { label: 'License Plate' },
      freightType: { label: 'Cargo type' },
      freightCompany: { label: 'Transport Company' },
      freightInfo: { label: 'Cargo Info' },
      outShipmentTemplate: { label: 'Packing Slip Template' },
    },
    missingPickProcessCreate: 'Create missing Pick Order process',
  },
  outShipmentLine: {
    disabledDelete: 'Cannot delete line because Work Order is in progress or completed.',
    field: {
      id: { label: 'ID' },
      outShipment: { label: 'Pick ID' },
    },
    create: {
      title: 'Create Pick Order',
    },
    edit: {
      title: 'Edit Pick Order',
    },
    missingSellProcessWarning: 'No Outbound process found for this article type.',
    missingSellProcessCreate: 'Create missing Outbound process',
  },
  capability: {
    overview: {
      title: 'Skills',
      empty: 'No skills found.',
      addButton: 'Skill',
    },
    create: {
      title: 'Add Skill',
    },
    edit: {
      title: 'Edit Skill',
    },
    field: {
      name: { label: 'Name' },
      operatorCapabilities: {
        label: 'Operators',
        none: 'No operators configured.',
      },
    },
  },
  processPhase: {
    overview: {
      title: 'Process Steps',
      empty: 'No process steps found.',
      addButton: 'Process Step',
    },
    create: {
      title: 'Create Process Phase',
    },
    edit: {
      title: 'Edit Process Phase',
    },
    field: {
      icon: { label: 'Icon' },
      name: { label: 'Name' },
    },
  },
  batchType: {
    overview: {
      title: 'Processes',
      empty: 'No processes found.',
    },
    create: {
      title: 'Add Process',
    },
    onTheFly: {
      title: 'On the Fly Settings',
      description: 'With \'On-the-Fly\' Tracy can automatically create a new batch when a serial number is scanned which isn\'t known yet. The entered serial number has to match the serial format.',
    },
    edit: {
      title: 'Edit Process',
      bulkTemplateButton: 'Bulk Add Template',
      bulkTemplateModal: {
        title: 'Bulk Add Template',
        selectButton_one: 'Add to {{count}} Article Type',
        selectButton_other: 'Add to {{count}} Article Types',
        selectAllButton: 'Add to all {{count}} Article Types',
      },
      bulkTemplateProgressModal: {
        title: 'Bulk Add Progress',
        progress: '{{count}} of {{total}} added.',
        timeRemaining: '{{time}} remaining.',
      },
      finalizeProgressModal: {
        title: 'Finalizing process template progress',
      },
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Description' },
      quantity: { label: 'Batchsize' },
      name: { label: 'Batchsize' },
      articleType: { label: 'Article Type' },
      onTheFlySerialNumberFormat: { label: 'On the Fly Serial Number Format' },
      type: {
        label: 'Type',
        value: {
          make: 'Make',
          buy: 'Inbound',
          sell: 'Outbound',
          transfer_line: 'Transfer line',
          component: 'Component',
          subassembly: 'Subassembly',
          stock_count: 'Stock Count',
          stock_count_template: 'Stock Count Template',
          transfer: 'Transfer',
          on_the_fly: 'On the Fly',
          pick_order: 'Pick Order',
          receive_order: 'Receive Order',
          make_template: 'Make Template',
          buy_template: 'Inbound Template',
          sell_template: 'Outbound Template',
          transfer_line_template: 'Transfer Line Template',
          on_the_fly_template: 'On the Fly Template',
        },
      },
      serialNumberFormat: { label: 'Serial Format' },
      default: { label: 'Default' },
      source: { label: 'Template' },
      targets: {
        label: 'Article Types',
        value_one: 'Used in {{count}} article type.',
        value_other: 'Used in {{count}} article types.',
      },
      onTheFlyType: {
        label: 'On the Fly Batch Size',
        info: 'This setting determines which size the batches will be when they are created on the fly.',
        value: {
          none: 'None',
          fixed: 'Fixed',
          flexible: 'Flexible',
        },
        tooltip: {
          none: 'If you select this option we will not actually look at the quantity of the created batch, it can be used infinitely many times.',
          fixed: 'If you select this option you will have to enter a fixed size for the created batches.',
          flexible: 'If you select this option the operator will be prompted to enter the size of the batch when a new batch has to be created.',
        },
      },
      onTheFlySource: {
        label: 'On the Fly Source',
        info: 'This setting determines where the batch that will be created on the fly comes from.',
        value: {
          new: 'New',
          existing: 'Existing',
        },
        tooltip: {
          new: 'A new batch will be created.',
          existing: 'The system will prompt to scan an existing batch that the new batch will be taken from as a subbatch.',
        },
      },
      autoAddProcessToNewArticles: { label: 'Auto add process to new synced article types' },
      classification: { label: 'Add to article type group' },
    },
  },
  articleType: {
    overview: {
      title: 'Article Types',
      empty: 'No Article Types found.',
      addButton: 'Article Type',
      viewBOMButton: 'View BOM',
      viewAssemblyBOMButton: 'View Assembly BOM',
      copyBatchTypeButton: 'Copy Batch Types',
    },
    printShelfLabelButton: 'Stock Location Label',
    selectModal: {
      title: 'Choose Article Type',
    },
    create: {
      title: 'Add Article Type',
    },
    edit: {
      baseFields: {
        label: 'Basic Fields',
        info: 'These fields do not belong to a process type, but apply to the entire article.',
      },
      title: 'Edit Article',
      version: 'Version {{version}}',
      draft: 'Concept',
      finalizeButton: 'Finalize',
      nextVersionButton: 'Next Version',
      noStepSelected: 'No step selected',
      workStationsModal: {
        title: 'Select Work Stations',
      },
      copyPaste: {
        copyButton: 'Copy',
        pasteButton: 'Paste',
        copied: 'Copied successfully.',
        pasted: 'Pasted successfully.',
        pasteError: 'Nothing to paste.',
      },
      bomFieldsModal: {
        title: 'BOM Fields',
        content: 'This process contains form fields of type \'BOM\'. Switching to another batch type than \'make\' will cause these fields to be deleted.',
      },
      endWithVariableQuantity: 'The process cannot end with a variable quantity. Add a quantity form field to define the actual batch size.',
      splitWithVariableQuantity: 'A batch step cannot be used on a variable quantity.',
      quantityFieldWithoutVariableQuantity: 'A quantity field cannot be used without a variable quantity.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      name: { label: 'Name' },
      line: { label: 'Production Area' },
      versions: { label: 'Versions' },
      batchTypes: { label: 'Processes' },
      articleType: { label: 'Article Type' },
      componentType: { label: 'Component Type' },
      storageLocations: { short: 'Storage Location(s)', long: 'Storage Location(s) - Quantity' },
      classification: { label: 'Group' },
      startDate: { label: 'Start Date' },
      endDate: { label: 'End Date' },
      isMakeOrBuy: { label: 'Make/Buy' },
      isAssembly: { label: 'Assembly', filter: 'Is Assembly?' },
      isMake: { label: 'Make', filter: 'Is Make?' },
      isBuy: { label: 'Buy', filter: 'Is Buy?' },
      availableBatches: {
        label: 'Available Batch(es)',
        value_one: '{{count}} available batch ',
        value_other: '{{count}} available batches',
        noBatch: 'No available batch',
      },
      isSerial: { label: 'Serial' },
      totalStock: { label: '# Available' },
      storageLocationStock: { label: 'Storage Location Stock' },
      deleted: { label: 'Deleted' },
      endDateBeforeCurrDate: { label: 'Show end-date before today' },
      myTrackBatchUsage: {
        label: 'Track batch usage',
      },
      trackBatchUsage: {
        label: 'Track batch usage',
        followClassificationCheckbox: 'Follow group',
        followClassificationHelpLine1: 'When checked, it will follow what is configured for the connected group',
        followClassificationHelpLine2: 'It\'s currently configured as'
      },
      unit: { label: 'Unit' },
      extraDescription: { label: 'Extra Description' },
      searchCode: { label: 'Search Code' },
      volume: { label: 'Volume' },
      volumeFactor: { label: 'Volume Factor' },
    },
  },
  articleTypeVersion: {
    field: {
      articleType: { label: 'Article Type' },
      productionLineVersion: { label: 'Production Area Version' },
    },
  },
  plantationLine: {
    overview: {
      title: 'Plantation Areas',
      empty: 'No plantation area found.',
      addButton: 'Plantation Area',
      details: 'Details',
      filters: 'Filters',
      rack: { label: 'Rack', value: '{{rack}}' },
      location: { label: 'Position', value: 'R{{rack}}L{{layer}}P{{position}}' },
      container: { label: 'Container' },
      time: {
        start: 'Start',
        end: 'End',
      },
      scripts: {
        label: 'Values',
        setpoint: {
          name: 'Name',
          value: 'Value',
        },
      },
    },
    create: {
      title: 'Add Plantation Area',
    },
    edit: {
      title: 'Edit Plantation Area',
    },
  },
  productionLine: {
    overview: {
      title: 'Production Areas',
      empty: 'No production area found.',
      addButton: 'Production Area',
    },
    planning: {
      title: 'Production Areas',
      empty: 'No production area found.',
      productionLine: 'Production Area',
      week: 'Week',
      days: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
      },
      parts: {
        night: 'Night (00:00 - 06:00)',
        morning: 'Morning (06:00 - 12:00)',
        afternoon: 'Afternoon (12:00 - 18:00)',
        evening: 'Evening (18:00 - 00:00)',
      },
    },
    create: {
      title: 'Add Production Area',
    },
    edit: {
      baseFields: {
        label: 'Global Settings',
        info: 'These fields do not belong to a version but are applicable to every version.',
      },
      title: 'Edit Production Area',
      version: 'Version {{version}}',
      draft: 'Concept',
      finalizeButton: 'Finalize',
      nextVersionButton: 'Next version',
      tabs: {
        map: 'Map',
        codes: 'Codes',
        availability: 'Availability',
      },
    },
    selectModal: {
      title: 'Select a production area.',
    },
    codesModal: {
      title: 'Codes Overview',
      noPrintersAvailable: 'No printers available.',
    },
    field: {
      name: { label: 'Name' },
      fullName: { label: 'Name' },
      versions: { label: 'Versions' },
      timezone: { label: 'Timezone' },
      color: {
        label: 'Colour',
        value: {
          red: 'Red',
          orange: 'Orange',
          yellow: 'Yellow',
          olive: 'Olive green',
          green: 'Green',
          teal: 'Turquoise',
          blue: 'Blue',
          violet: 'Violet',
          purple: 'Purple',
          pink: 'Pink',
          brown: 'Brown',
          grey: 'Gray',
          black: 'Black',
        },
      },
      workSchedules: {
        label: 'Schedules',
        errors: {
          missing: 'Production line must have a valid schedule.',
        }
      },
      leaveCalendars: { label: 'Leave Calendars' },
      factory: { label: 'Factory' },
    },
  },
  productionLineVersion: {
    field: {
      version: { label: 'Version' },
      draft: { label: 'Concept' },
      floorPlan: { label: 'Layout' },
      workStations: {
        label: 'Work Stations',
        addButton: 'Work Station',
        error: {
          missing: 'At least one work station is required.',
        }
      },
      productionLine: { label: 'Production Area' },
    },
  },
  workloadPlanner: {
    title: 'Scheduler',
    empty: 'There are no planned orders for the selected period.',
    toPlanEmpty: 'There are no orders to plan for the selected period.',
    relation: 'Relation',
    processType: {
      label: 'Process Type',
      value: {
        make: 'Production Orders {{count}}',
        buy: 'Sub Inbound',
        sell: 'Sub Outbound',
        transfer_line: 'Sub Transfer line',
        stock_count: 'Stock Count {{count}}',
        transfer: 'Transfer Orders {{count}}',
        pick_order: 'Pick Orders {{count}}',
        receive_order: 'Receive Orders {{count}}',
      },
    },
    status: {
      value: {
        open: 'Open',
        needsPreparation: 'Needs preparation',
        ready: 'Ready',
        inProgress: 'In progress',
        done: 'Done',
        released: 'Released to the floor',
      },
    },
    fields: {
      orderTags: { label: 'Order tags' },
      highestReceiptDate: { label: 'Highest Receipt date' },
      allStock: { label: 'All stock' },
      earliestDeliveryDate: { label: 'Earliest delivery date' },
      noAllocation: { label: 'No allocated/linked sales orders.' },
      noPurchaseOrderLines: { label: 'No purchase order lines linked to the BOM.' },
      articleGroup: { label: 'Article Group' },
    },
    bulkAction: {
      moveToPrevious: 'Move to previous week',
      moveToNext: 'Move to next week',
      unreleaseFromFloor: 'Un-release from the floor',
      releaseToFloor: 'Release to the floor',
    },
    releaseWarning: {
      title: 'Warning: Insufficient Materials Stock',
      currentStockInsufficient: 'Current stock for PR{{id}} materials is insufficient, are you sure you want to continue?',
      futureStockInsufficient: 'Future stock for PR{{id}} materials is insufficient, are you sure you want to continue?'
    },
  },
  workSchedule: {
    field: {
      employer: { label: 'Employer', placeholder: 'Start typing to search or add employers' },
      startDate: { label: 'Start Date' },
      workSlots: {
        label: 'Work Times',
        empty: 'No work times have been added yet.',
      },
      allowedLegalLeaveMinutes: { label: 'Legal Vacation Minutes' },
      allowedExtraLeaveMinutes: { label: 'Extra Vacation Minutes' },
      allowedRwhMinutes: { label: 'RWH-days' },
    },
  },
  workStation: {
    field: {
      name: { label: 'Name' },
      code: { label: 'Code' },
      dataSources: { label: 'Data Source' },
      defaultFte: { label: 'Standard Staff' },
      isAdministration: { label: 'Administrative workstation' },
      view: { label: 'View' },
      singleUser: {
        label: 'Login Behaviour',
        value: {
          true: 'Single Login',
          false: 'Multi Login',
        },
        tooltip: {
          true:
            'When set as \'Single Login\' The operator is asked to scan the badge once. By default, after 4h the station will auto log off. This time can be changed by changing the variable single_user_work_station_timeout_minutes in the general settings.',
          false: 'When set as \'Multi Login\' The operator is asked to scan the badge every step.',
        },
      },
      productionStrategy: {
        label: 'Production strategy',
        value: {
          together: 'Together',
          seperate_spread: 'Separate Spread',
          seperate_planned: 'Separate Planned',
        },
        tooltip: {
          together: 'When set as \'Together\', the stations will act as 1 station',
          seperate_spread: 'When set as \'Separate Spread\', the stations will work separately on the same orders.',
          seperate_planned: 'When set as \'Separate Planned\', the stations will work on different orders as planned.',
        },
      },
      maintenanceStrategy: {
        label: 'Maintenance Strategy',
        value: {
          together: 'Together',
          seperate: 'Separate',
        },
        tooltip: {
          together: 'When set as \'Together\', maintenance will be performed for all locations at a time.',
          seperate: 'When set as \'Separate\', maintenance will be planned and performed for each location separately.',
        },
      },
      steps: { label: 'Steps' },
      automationDate: { label: 'Automation Date' },
      work: { label: 'Work' },
      workAssigned: { label: 'Assigned' },
      workUnassigned: { label: 'Unassigned' },
      productionRequests: { label: 'Work Orders' },
      externalWorkStations: { label: 'External WorkStations' },
    },
    start: {
      title: 'Login Work Station',
      startButton: 'Start',
    },
    production: {
      title: 'Production',
      empty: 'No orders could be found.',
      stopButton: 'Stop',
      selectButton: 'Perform',
      version: 'Version {{version}}',
      operatorModal: {
        title: 'Scan Badge ID',
        confirmButton: 'Confirm',
        error: {
          noResults: 'Badge-ID not found.',
          emptyField: 'Enter the Badge-ID of the operator.',
          multipleResults: 'Multiple operators found with same Badge ID.',
          unexpected: 'A unexpected error occured.',
          missingCapabilities: 'Incorrect skills: {{capabilities}}.',
        },
      },
      batchModal: {
        title: 'Scan Product',
        confirmButton: 'Confirm',
        loadCarriers: 'Scan Load carrier',
        error: {
          notFound: 'No product found with this serial number.',
        },
      },
      performModal: {
        generalErrors: 'Some errors occured.',
        title: 'Perform Step',
        confirmButton: 'Confirm & to next step [{{count}}]',
        confirmAndOverviewButton: 'Confirm & to overview [{{count}}]',
        confirmAndNextBatchButton: 'Confirm & to next batch [{{count}}]',
        stillToDo: 'Still to do',
        goToMain: 'Go to next lines',
        formStep: {
          label: 'Serialnumber',
          subject: {
            article_type: 'Article',
          },
          saveProgress: 'Save',
          printProgress: 'Save & Print label',
        },
        tasksCheck: {
          header: 'Tasks/Material unfinished!',
          content: 'Are you sure you want to finish this step? You did not finish all tasks and/or materials.',
          closeButton: 'Go back',
          confirmButton: 'Finish step',
        },
        carrierStep: {
          title: 'Shipment input',
          deliveryAddress: 'Delivery address',
          total: 'Total',
          length: 'Length',
          width: 'Width',
          height: 'Height',
          volume: 'Volume',
          weigth: 'Weight',
          trackTrace: 'Track and trace',
          Retrieve: 'retrieve track and trace',
          send_request: {
            label: 'Send order',
          },
          printButton: 'Print',
          email: 'Email',
          phoneNumber: 'Phone number ',
          reference: 'Reference',
          amount: 'Quantity',
          country: 'Country code',
          extraServices: 'Additional services',
          upsServices: 'UPS Services',
          closeButton: 'close',
          confirmButton: 'Send',
          link: 'Select to add pick lines',
          confirm: {
            header: 'Are you sure?',
            content: 'Are you sure you want to execute this step?',
          },
          pickShippingUnit: 'Type',
          documentHeader: 'Attach document to shipment',
          skipPrint: 'Skip print',
        },
        printStep: {
          selectPrinter: 'Select Printer',
          noPrintersAvailable: 'No Printers Available',
          printButton: 'Print',
          skipPrintButton: 'Postpone print',
          printedSuccesfully: 'Printed successfully',
          printSkipped: 'Print postponed',
        },
        splitStep: {
          printButton: 'Confirm & print',
          skipPrintButton: 'Confirm & postpone print',
          printAllButton: 'Print all ({{count}})',
          reprintButton_one: 'Reprint',
          reprintButton_other: 'Reprint ({{count}})',
          bulkEntrySerialNumbersDetail: 'You can scan multiple serial numbers at once.',
          wrongFormat: 'The serial number {{serialNumber}} does not match the predetermined format',
          expectedQuantity: 'You already scanned the expected number of serial numbers.',
          integrationNotActive: 'Exact integration is not activated or does not exist.',
          alreadySyncedToExact: 'The serial number {{serialNumber}} has already been scanned.',
        },
        quantityType: {
          quantity: 'Quantity',
          weight: 'Weight',
          error: {
            tooMuch: 'The quantity provided is higher than the quantity left for this order.',
          }
        },
        scan: {
          serialNumber: 'Serial Number',
          quantity: 'Quantity',
          error: {
            notFound: 'No batch found with the provided serial number.',
            wrongArticleType: 'The batch found with the provided serial number does not match the expected article type.',
            wrongBatchType: 'The batch found with the provided serial number is not of a batch type that can be scanned.',
            fullyUsed: 'The batch found with the provided serial number does not have any stock remaining.',
            constraint: {
              noValue: 'The batch found with the provided serial number does not have a value for {{field}}.',
              doesNotHold: {
                eq: 'The batch found with the provided serial number has {{leftField}} {{left}} which is not equal to {{right}}.',
                neq: 'The batch found with the provided serial number has {{leftField}} {{left}} which is equal to {{right}}.',
                lt: 'The batch found with the provided serial number has {{leftField}} {{left}} which is not less than {{right}}.',
                lte: 'The batch found with the provided serial number has {{leftField}} {{left}} which is not less than or equal to {{right}}.',
                gt: 'The batch found with the provided serial number has {{leftField}} {{left}} which is not greater than {{right}}.',
                gte: 'The batch found with the provided serial number has {{leftField}} {{left}} which is not greater than or equal to {{right}}.',
              },
            },
          },
        },
        instructions: {
          title: 'Instructions',
          noInstructions: 'No step instructions has been defined.',
          doneBtn: 'Done',
          document: {
            id: 'ID',
            description: 'Description',
            subject: 'Subject',
            date: 'Date',
          }
        }
      },
      quantityModal: {
        title: 'Enter Batch Quantity',
        quantity: 'Quantity',
        confirmButton: 'Confirm',
        quantityWarning: 'The quantity inputed is higher than the ordered quantity ({{quantity}})!',
      },
      infoModal: {
        title: 'Info',
      },
      fastForwardButton: 'Fast Forward',
      fastForwardModal: {
        title: 'Fast Forward',
        days: 'Days',
        confirmButton: 'Confirm',
      },
      operator: {
        expiresAt: 'Ends at{{at}}',
      },
      onTheFlyQuantityModal: {
        title: 'On the Fly Quantity',
        description: 'The scanned batch does not exist yet in the system. In order to create it on the fly the system needs to know its quantity.',
        placeholder: 'Quantity',
      },
      onTheFlySourceModal: {
        title: 'On the Fly Source',
        description: 'The scanned batch does not exist yet in the system. In order to create it on the fly the system needs to know the source batch it was taken from.',
        placeholder: 'Serial Number',
        error: {
          notFound: 'No batch found with the provided serial number.',
          wrongArticleType: 'The batch found with the provided serial number does not match the expected article type.',
          notEnough: 'The batch found with the provided serial number does not have enough stock remaining.',
        },
      },
      pickFromListModal: {
        title: 'Pick: {{articleType}}',
      },
      scanToPerformModal: {
        title: 'Scan barcode / serialnumber to perform',
        confirmBtn: 'Start',
        infoBtn: 'Batch info',
        cancelBtn: 'Cancel',
        error: {
          notFound: 'No product found with this serial number.',
          missingSkills: 'You do not have the skills to perform this process. Skills needed: {{skills}}',
          wrongWorkstation: 'Scanned product belongs to a different workstation. Correct workstation is {{workstation}}'
        },
      },
    },
    editModal: {
      editTitle: 'Configure Work Station',
      viewTitle: 'Work Station Configuration',
    },
    stopModal: {
      title: 'Logout Work Station',
      content: 'Are you sure you want to end this Work Station?',
      stopButton: 'Logout',
    },
    planning: {
      title: 'Daily Planning',
      empty: 'Nothing to plan for this day.',
      assignmentsModal: {
        title: 'Daily Planning: {{name}}',
      },
      assignmentTimeModal: {
        title: 'Change Times',
        overlap: {
          label: 'These times overlap with some already assigned slots for this operator.',
          assignment: '{{workStation}} from {{start}} until {{end}}.',
        },
      },
      day: {
        today: 'Today',
        tomorrow: 'Tomorrow',
        other: 'Other',
      },
      stats: {
        work: 'Hours of work',
        workAssigned: 'Assigned hours',
        workUnassigned: 'Unassigned hours',
        month: {
          january: 'JANUARY',
          february: 'FEBRUARY',
          march: 'MARCH',
          april: 'APRIL',
          may: 'MAY',
          june: 'JUNE',
          july: 'JULY',
          august: 'AUGUST',
          september: 'SEPTEMBER',
          october: 'OCTOBER',
          november: 'NOVEMBER',
          december: 'DECEMBER',
        },
      },
    },
    day: {
      today: 'Today',
      tomorrow: 'Tomorrow',
    },
    views: {
      default: 'Default',
      export: 'Import / Export'
    }
  },
  productionRequest: {
    reduceQuantityModal: {
      header: 'Reduce quantity',
      content: 'Here we can decide to produce less than the original production request quantity. When applied, the quantity will be reduced to {{quantityReduced}}.',
    },
    finishModal: {
      header: 'Manually finish Production Request',
    },
    registerExistingButton: 'STOCK COUNT',
    overview: {
      title: 'Workload',
      empty: 'No work orders could be found',
      addButton: 'Production Order',
      createWarehouseTransferButton: {
        fromMissing: 'Create Transfer from Missing Articles',
        fromMaterials: 'Create Transfer from Material Plan',
        multipleWarehouses: 'More than 1 warehouse selected'
      },
      bulkChangePlanned: {
        button: 'Plan orders',
        title: 'Bulk Plan orders',
        confirm: 'Plan orders ({{date}})',
      },
      productionRequestID: 'Work Order ID',
      inShipmentId: 'Receive Order ID',
      outShipmentId: 'Pick Order ID',
      exactSalesOrder: 'Open Sales Order {{order}} in Exact',
      exactPurchaseOrder: 'Open Purchase Order {{order}} in Exact',
      exactShopOrder: 'Open Production Order {{order}} in Exact',
      purchaseOrderNumber: 'Purchase Order ID',
      stockCountNumber: 'Stock Count ID',
      salesOrderNumber: 'Sales Order ID',
      linkedSalesOrder: 'Linked Sales Order ERP {{order}}',
      warehouseTransferID: 'Warehouse Transfer ID',
      warehouseTransferLineID: 'Warehouse Transfer Line ID',
      productionOrderNumber: 'Production Order ID',
      articleTypeCode: 'Article Type CODE',
      orders: 'Orders',
      salesOrders: 'Sales Orders',
      done_one: '{{count}} product done',
      done_other: '{{count}} products done',
      progress: '{{percentage}} of work done',
      extra_one: '+ {{count}} extra',
      extra_other: '+ {{count}} extra',
      diff: {
        planned: 'Planned',
        actual: 'Actual',
        advance: 'Advance',
        delay: 'Delay',
      },
      information: 'Information',
      order: 'Order',
      processSteps: 'Process steps',
      warehouse: 'Warehouse',
      removeFromPlanning: 'Remove from current planning',
      unrelease: 'Unrelease',
      createdBy: 'Created by {{creator}}',
      plannedOn: 'Planned on'
    },
    edit: {
      title: 'Edit Work Order',
      bom: 'BOM',
      storageLocations: 'STORAGE LOCATIONS',
      releaseButton: 'Release to shopfloor',
      unreleaseButton: 'Unrelease from shopfloor',
      stage: {
        articleType: {
          label: 'Article Type Info',
          bom: {
            notEnoughStock: 'Not enough stock available to produce this quantity',
          },
          showBomBtn: 'Show BOM',
          missingMakeProcess: 'Missing default finalized make process',
        },
        warehouse: {
          label: 'Warehouse Info',
        },
        planning: {
          label: 'Planning',
          planned: 'Planned',
          actual: 'Actual',
          duration: 'Duration',
          start: 'Start',
          end: 'End',
        },
        bom: {
          label: 'BOM List',
          articleCode: 'Article Type Code',
          articleName: 'Article Type Name',
          taskCode: 'Code',
          taskName: 'Name',
          duration: 'Duration',
          workStation: 'Work station',
          unit: 'Unit',
          stock: 'Stock',
          plannedIn: 'Planned In',
          plannedOut: 'Planned Out',
        },
        materialPlan: {
          label: 'Material Plan',
        },
        tasks: {
          label: 'Tasks'
        },
        byProducts: {
          label: 'By Products',
        },
        productionLines: {
          label: 'Production Areas',
        },
        metafields: {
          label: 'Metafields',
        },
      },
    },
    field: {
      id: { label: 'Work Order ID' },
      articleType: { label: 'Article Type' },
      articleTypeDescription: { label: 'Article Type Description' },
      processVersion: { label: 'Version' },
      week: { label: 'Week' },
      quantity: { label: 'Quantity' },
      progress: { label: 'Progress' },
      quantityDone: { label: 'Quantity Finished' },
      startAt: { label: 'Start' },
      endAt: { label: 'End' },
      duration: { label: 'Duration' },
      project: { label: 'Project' },
      finished: { label: 'Finished' },
      productionOrder: { label: 'Production Order' },
      totalProcessTime: { label: 'Total Process Time' },
      flagged: { label: 'Flagged' },
      producedQuantity: { label: 'Produced Quantity' },
      inboundTrafficLight: { label: 'Purchase status' },
      outboundTrafficLight: { label: 'Overdue status' },
      material_planTrafficLight: { label: 'Stock status' },
      capacityTrafficLight: { label: 'Capacity status' },
      erpNumber: { label: 'ERP ID' },
      erpStartDate: { label: 'ERP Start Date' },
      manualFinishedReason: { label: 'Manual finished reason' },
    },
    filter: {
      itemGroup: 'Item Group',
      requestStatus: 'Work Order Status',
      inShipmentId: 'Receive ID',
      outShipmentId: 'Pick ID',
      warehouseTransferID: 'Transfer ID',
      projectCode: 'Project Code',
      purchaseOrderNumberERP: 'Purchase Order ERP',
      salesOrderNumberERP: 'Sales Order ERP',
      shippingMethod: 'Shipping Method',
      orderType: 'Order Type',
      erpId: 'ERP ID',
      id: 'WO ID',
      articleCode: 'Article Code',
      erpStartDate: 'ERP Start Date',
      tracyStartAt: 'Planned Start Date',
      filterByStatus: 'Filter by Status',
      nestId: 'Nest ID',
      subProductionRequestMetavalues: 'Sub Production Request Metavalues',
      markedColors: 'Marked Colors',
      linkedSalesOrder: 'Linked Sales Order ERP',
      today: 'Today',
      days_3: 'Following 3 days',
      days_7: 'Following 7 days',
      past: 'Start day has passed',
    },
    addModal: {
      title: 'Add Order',
      step: {
        pickWeek: 'Select week',
        pickArticleTypes: 'Select article type',
        setQuantities: 'Select quantity',
      },
      quantityWarning: 'Amending the order quanitity to 0 is equal to deleting the order.',
      noArticleTypes: 'No article types selected.',
      notFullyDeleted: 'The order could not be deleted because it is in progress.',
    },
    editModal: {
      title_one: 'Edit Order',
      title_other: 'Edit Orders',
    },
    nest: {
      makeNest: 'Create nest'
    },
    notStartedYet: 'Not started yet',
    erpEnd: 'ERP end'
  },
  orderHistory: {
    overview: {
      title: 'Orders History',
      empty: 'No orders could be found.',
      productionRequestID: 'Work Order ID',
      purchaseOrderNumber: 'Purchase Order ID',
      salesOrderNumber: 'Sales Order ID',
      productionOrderNumber: 'Production Order ID',
      orders: 'Orders',
      plannedOn: 'Planned on'
    },
    field: {
      id: { label: 'Work Order ID' },
      articleType: { label: 'Article Type' },
      quantity: { label: 'Quantity' },
      progress: { label: 'Progress' },
      startAt: { label: 'Start' },
      endAt: { label: 'End' },
      finished: { label: 'Finished' },
      erpNumber: { label: 'ERP ID' },
    },
    filter: {
      released: 'Released',
      itemGroup: 'Item Group',
      warehouseTransferID: 'Transfer ID',
      shippingMethod: 'Shipping Method',
      erpId: 'ERP ID',
      articleCode: 'Article Code',
      nestId: 'Nest ID',
    },
  },
  myFilter: {
    custom: 'New',
    action: {
      unsetDefault: 'Use as standard',
      setDefault: 'Use as standard',
      setCurrent: 'Save current filters',
      delete: 'Delete',
    },
  },
  globalValue: {
    edit: {
      unsetButton: 'Unset',
      title: 'Edit {{key}}',
      operator_badge_print: {
        key: {
          operator_name: 'The operator\'s name.',
          badge_id: 'The operator\'s badge ID.',
        },
      },
      workstation_code_print: {
        key: {
          workstation_name: 'The work station\'s name.',
          workstation_code: 'The work station\'s code.',
        },
      },
      stock_location_print: {
        key: {
          article_type_code: 'The article type\'s code.',
          article_type_name: 'The article type\'s name.',
          warehouse_code: 'The warehouse\'s name.',
          warehouse_locations: 'The warehouse\'s locations.',
          erp_order_id: 'The order\'s ERP ID.',
          date: 'The date of printing.',
          time: 'The time of printing.',
          best_before_date: 'Best before date',
        },
      },
      loading_carrier_print: {
        key: {
          loading_carrier_serial: 'Serial number of the loading carrier',
          loading_carrier_name: 'Name of the loading carrier',
        },
      },
      login_url_print: {
        key: {
          slug: 'The slug of your tracy instance. ({{value}})',
          app_url: 'The base url of tracy. ({{value}})',
        },
      },
      progress_scope: {
        week: 'Week',
        month: 'Month',
        day: 'Day',
        none: 'None',
      },
      tracy_tag_size: {
        'normal': 'Normal (icon & text)',
        'mini': 'Mini (only icon)'
      },
      tracy_app_image_quality: {
        low: 'Low: 640x640',
        medium: 'Medium: 1024x1024',
        high: 'High: 2048x2048',
      },
      shipping_label: {
        key: {
          delivery_address: 'The delivery address of the order.',
          delivery_name: 'The delivery name of the order',
          serial_number: 'The batch\'s serial number.',
          erp_order_id: 'The order\'s ERP ID.',
          carrier_barcode: 'The barcode of the carrier.',
          carrier_depot: 'The depot of the carrier.',
          carrier_order: 'The carrier\'s request ID.',
          carrier_numberofcollo: 'The carrier\'s number of packages.',
          carrier_totalweight: 'The carrier\'s total weight.',
          carrier_collodimensions: 'The carrier\'s packages dimensions.',
          carrier_collonumber: 'The package\'s number.',
          customer_name: 'The customer\'s name.'
        },
      },
      sub_batches_print_label: {
        key: {
          operator_badge_id: 'The badge ID of the operator performing the step where this is printed.',
          serial_number: 'The batch\'s serial number.',
          erp_order_id: 'The order\'s ERP ID.',
          article_type_code: 'The code of the nested article type.',
          article_type_name: 'The name of the nested article type.',
          quantity: 'The quantity of the batch.',
          date: 'The date of printing.',
          time: 'The time of printing.',
          order_id: 'The batch\'s production order ID.',
          material_plan_line_description: 'The material plan line description'
        },
      },
      rework_label: {
        key: {
          date: 'The date of printing.',
          time: 'The time of printing.',
          serial_number: 'The batch\'s serial number.',
          article_type_code: 'The code of the nested article type.',
          article_type_name: 'The name of the nested article type.',
          erp_order_id: 'The order\'s ERP ID.',
          quantity: 'The quantity of the batch.',
          order_id: 'The batch\'s production order ID.',
        },
      },
      clockInOutRoundOff: {
        label: 'Round off clock in / out times ?',
        useGlobal: 'Use global setting `clock_in_out_round_off`',
        0: 'No rounding',
        15: 'Round up clock in to next quarter',
        30: 'Round up clock in to next half hour',
        '-15': 'Round off clock out to previous quarter',
        '-30': 'Round off clock out to previous half hour',
      },
    },
    overview: {
      title: 'Global settings',
      template: {
        empty: 'Template is empty',
        moreLines_one: '{{count}} more line hidden, hover to show.',
        moreLines_other: '{{count}} more lines hidden, hover to show.',
      },
    },
    field: {
      key: {
        label: 'Setting name',
      },
      value: {
        label: 'Value',
      },
      writable: {
        label: 'Writable?',
      },
      readable: {
        label: 'Readable?',
      },
    },
  },
  template: {
    field: {
      name: { label: 'Name' },
      file: { label: 'PDF' },
      type: {
        label: 'Type',
        value: {
          out_shipment: 'Packing Slip',
        },
      },
      isDefault: { label: 'Default' },
    },
    overview: {
      title: 'Templates',
      empty: 'No templates have been added',
      addButton: 'Template',
    },
    create: {
      title: 'Create Template',
    },
    edit: {
      title: 'Edit Template',
    },
    info: 'In a template you can add product variables by typing {{ and selecting the correct value.',
  },
  globalFile: {
    edit: {
      title: 'Edit special file',
    },
    overview: {
      title: 'Special files',
    },
    field: {
      key: {
        label: 'Name for file',
      },
      value: {
        label: 'File',
      },
      writable: {
        label: 'Writable?',
      },
      readable: {
        label: 'Readable?',
      },
    },
  },
  tooltips: {
    masquerade: 'Masquerade as',
    edit: 'Edit',
    delete: 'Delete',
    restore: 'Restore',
    view: 'View',
    select: 'Select',
    finish: 'Finish',
    unfinish: 'Unfinish',
  },
  form: {
    // Model
    edit: {
      addFieldButton: 'Add field',
    },
    field: {
      fields: {
        label: 'Fields',
        empty: 'The form is empty',
      },
    },
    // Form stuff
    cap: {
      remaining: '{{count}} remaining',
      tooMany: '{{count}} too many',
      tooLittle: '{{count}} too little',
    },
    yes: 'Yes',
    no: 'No',
    either: 'Both',
    draftJs: {
      style: {
        'header-one': { label: 'H1' },
        'header-two': { label: 'H2' },
        'header-three': { label: 'H3' },
        'header-four': { label: 'H4' },
        'header-five': { label: 'H5' },
        'header-six': { label: 'H6' },
        blockquote: { label: 'Blockquote' },
        'unordered-list-item': { label: 'UL' },
        'ordered-list-item': { label: 'OL' },
        'code-block': { label: 'Code Block' },
        BOLD: { label: 'Bold' },
        ITALIC: { label: 'Italic' },
        UNDERLINE: { label: 'Underline' },
        CODE: { label: 'Monospace' },
      },
    },
    iconInput: {
      noneSelected: 'No icon selected',
    },
    applyButton: 'Apply',
    cancelButton: 'Cancel',
    deleteButton: 'Delete',
    restoreButton: 'Restore',
    backButton: 'Back',
    nextButton: 'Next',
    submitButton: 'Submit',
    saveButton: 'Save',
    downloadButton: 'Download',
    copyButton: 'Copy',
    addButton: 'Add',
    printButton: 'Print',
    bulkActionButton: 'Action ({{count}})',
    fileType: {
      pdf: 'PDF',
      image: 'IMG',
      xls: 'XLS',
      any: 'Other',
      none: 'No file uploaded yet',
    },
    multiPick: {
      searchPlaceholder: 'Select…',
      selectedText: '$1 from $2 selected',
      noneSelectedText: 'None selected',
      selectAllButton: 'All',
      selectNoneButton: 'None',
    },
    notifications: {
      saveSuccess: 'Saved successfully',
      deleteSuccess: 'Deleted successfully',
      saveError: 'Error with saving ({{status}})',
      saveValError: 'Not all information is filled in correctly',
      saveAuthError: 'Invalid credentials',
      newAppVersion: 'New version available, click to reload page as soon as possible',
    },
    startDate: 'Start date',
    endDate: 'End date',
    deleteConfirmation: 'Are you sure you want to delete "{{name}}"?',
    restoreConfirmation: 'Are you sure you want to restore "{{name}}"?',
    repeat: 'Repeat',
    greaterThan: 'Higher than',
    lowerThan: 'Lower than',
    greaterThanOrEqual: '>=',
    lowerThanOrEqual: '<=',
    greaterThanSign: '>',
    lowerThanSign: '<',
    equal: '=',
    duration: {
      hours: 'Hours',
      minutes: 'Minutes',
      seconds: 'Seconds',
    },
  },
  crash: {
    heading: 'Application has crashed',
    subHeading1: 'We appologize, the application has crashed',
    subHeading2: 'Our team is notified.',
    reload: 'Reload page',
  },
  daycy: {
    week: {
      label: 'Wk',
      number: '{{week}}',
      value: 'W{{week}} - {{year}}',
    },
    weekDay: {
      monday: 'Mo',
      tuesday: 'Tu',
      wednesday: 'We',
      thursday: 'Th',
      friday: 'Fr',
      saturday: 'Sa',
      sunday: 'Su',
    },
    month: {
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'Oktober',
      november: 'November',
      december: 'December',
    },
    period: {
      am: 'AM',
      pm: 'PM',
    },
  },
  iconModal: {
    header: 'Select icon',
    filterPlaceholder: 'Search...',
    category: {
      favorites: 'Favorites',
      accessibility: 'Accessibility',
      arrows: 'Arrows',
      audioVideo: 'Audio/Video',
      business: 'Business',
      chess: 'Chess',
      code: 'Code',
      communication: 'Communication',
      computers: 'Computers',
      currency: 'Currency',
      dateTime: 'Date/Time',
      design: 'Design',
      editors: 'Editing',
      files: 'Files',
      genders: 'Genders',
      handsGestures: 'Hand signatures',
      health: 'Health',
      images: 'Pictures',
      interfaces: 'Interfaces',
      logistics: 'Logistics',
      maps: 'Cards',
      medical: 'Medical',
      objects: 'Objects',
      paymentsShopping: 'Shopping',
      shapes: 'Shapes',
      sports: 'Sport',
      status: 'Status',
      usersPeople: 'People',
      vehicles: 'Vehicles',
      writing: 'Writing',
    },
  },
  exact: {
    ok: {
      title: 'Exact connection successful',
      body: 'Exact online is successfully connected.',
    },
    error: {
      title: 'Exact connection failed',
      body: {
        invalid_request: 'Sending request to exact failed.',
        invalid_client: 'Sending client to Exact failed.',
        invalid_grant: 'Sending grant to Exact failed.',
        invalid_scope: 'Sending cope to Exact failed. ',
        unauthorized_client: 'Sending onauthorised client to Exact.',
        unsupported_grant_type: 'Sending grant to Exact failed.',
      },
    },
  },
  unit4: {
    ok: {
      title: 'Unit 4 connection successful',
      body: 'Unit 4 is successfully connected.',
    },
    error: {
      title: 'Unit 4 connection failed',
      body: {
        invalid_request: 'Sending request to exact failed.',
        invalid_client: 'Sending client to Exact failed.',
        invalid_grant: 'Sending grant to Exact failed.',
        invalid_scope: 'Sending cope to Exact failed. ',
        unauthorized_client: 'Sending onauthorised client to Unit 4.',
        unsupported_grant_type: 'Sending grant to Unit 4 failed.',
      },
    },
  },
  warehouse: {
    stock: 'Stock',
    inbound: 'Inbound',
    outbound: 'Outbound',
    edit: { title: 'Warehouse' },
    overview: {
      title: 'Warehouses overview',
      empty: 'No warehouses has been found.',
    },
    field: {
      id: { label: 'ID ' },
      code: { label: 'Code' },
      name: { label: 'Name' },
      useStorageLocations: { label: 'Uses storage locations?' },
      storageLocations: { label: 'Storage locations' },
      syncLocations: { label: 'Sync locations?' },
    },
  },
  step: {
    edit: {
      step: 'Step {{index}}',
      start: 'Start',
      end: 'End',
      addSectionButton: 'Add component',
    },
    field: {
      newBatchSerialNumberFormat: { label: 'New Serial Number Format' },
      label: { label: 'Process Step Name' },
      type: {
        value: {
          print: 'Print Step',
          form: 'Form Step',
          split: 'Batch Step',
          multiplier: 'Multiplier',
          subprocesses: 'Subprocesses',
          carrier: 'Carrier Step',
          byproduct: 'Byproduct step',
          nest: 'Nest step',
          export: 'Export step',
        },
        valueStep: {
          print: 'Print Step',
          form: 'Form Step',
          split: 'Batch Step',
          multiplier: 'Multiplier',
          subprocesses: 'Subprocesses',
          carrier: 'Carrier Step',
          byproduct: 'Byproduct step',
          nest: 'Nest step'
        },
      },
      sections: {
        label: 'Description',
        empty: 'This description is empty.',
      },
      workStation: { label: 'Work Station' },
      capabilities: { label: 'Skills required' },
      operatorSupervision: {
        label: 'Operator Supervision Required',
      },
      setupMinutes: {
        label: 'Setup Time',
        info: 'Estimate how long it will take to setup the step.',
        infoElaborate: 'This ‘setup’ is done once per order and not related to the order size. So an order of 1 has the same setup time as an order of 20.'
      },
      delayMinutes: {
        label: 'Wait Time',
        info: 'Estimate of the time between finishing the previous step and starting this step.',
        infoElaborate: 'This ‘wait’ is counted once per order and not related to the order size. So an order of 1 has the same wait time as an order of 20.'
      },
      workMinutes: {
        label: 'Run Time',
        info: 'Estimate how long it will take to perform the step.',
        infoElaborate: 'For the step duration the run time is multiplied by the order size. So an order of 20 takes 20 times as long as an order of 1.'
      },
      orderSize: {
        label: 'Order Size',
        explanation: 'Explore here with different order sizes to see how long this step would take.'
      },
      isLeadingBatch: {
        label: 'Leading Batch?',
        info: 'If selected as a leading batch, the next step will not create a new batch and use this one instead'
      }
    },
  },
  printStep: {
    field: {
      type: {
        label: 'Print Type',
        value: {
          label: 'Label',
          document: 'Document',
        },
      },
      copies: { label: 'Default Number of Copies' },
      labelPrinter: {
        label: 'Printer',
        value: {
          altec: 'Altec',
          zebra: 'Zebra/Toshiba',
        },
      },
      labelTemplate: {
        label: 'Template',
        keys: {
          sscc: 'SSCC standardized code',
          qr: 'Batch QR code',
          article_type_name: 'Article name.',
          article_type_code: 'Article code.',
          article_type_gross_weight: 'Article gross weight.',
          article_type_search_code: 'Article search code.',
          article_type_extra_description: 'Article extra description.',
          warehouse_name: 'Warehouse name.',
          warehouse_code: 'Warehouse code.',
          warehouse_locations: 'Warehouse locations.',
          serial_number: 'Product serialnumber.',
          batch_size: 'Batch size',
          date: 'Date.',
          time: 'Time.',
          shipment_lines: 'Shipment lines table.',
          shipment_lines_with_loading_carrier: 'Shipment lines table with loading carrier.',
          shipment_lines_without_meta: 'Shipment lines table without metafields.',
          operator_badge_id: 'Operator Badge ID',
          operator_name: 'Operator Name',
          order_id: 'Order ID',
          erp_order_id: 'ERP Order ID',
          customer_name: 'Order Customer Relation Name',
          supplier_name: 'Order Supplier Relation Name',
          reference: 'Order Reference',
          remarks: 'Order Remarks',
          delivery_address: 'Order Delivery Address',
          delivery_date: 'Order Delivery Date',
          delivery_name: 'Order Recipient Name',
          receipt_date: 'Order Receipt Date',
          quantity: 'Order Quantity',
          sales_order_line_description: 'Sales Order Line Description',
          purchase_order_line_description: 'Purchase Order Line Description',
          amount_ex_vat: 'Amount Excluding VAT',
          shipping_method_description: 'Shipping Method Description',
          linked_erp_sales_order_id: 'Linked Sales Order ERP Number',
          linked_erp_production_order_id: 'Linked Production Order ERP Number',
          linked_erp_purchase_orders_ids: 'Linked Purchase Order ERP Numbers',
          linked_erp_purchase_order_lines_ids: 'Linked Purchase Order Lines ERP Numbers',
          stock_count_default_storage_location_code: 'Stock count warehouse location.',
          linked_erp_sales_order_reference: 'Linked Sales Order ERP Reference',
        },
      },
      documentBackground: { label: 'Background' },
      documentOverlays: {
        label: 'Template',
        template: 'Template',
        addPage: 'Page',
        font_size: 'Font size',
        font_type: 'Font type',
      },
      defaultPrinter: {
        label: 'Default Printer',
        info: 'When printing, this printer will be selected by default if available.',
      },
    },
  },
  multiplierStep: {
    field: {
      multiplier: { label: 'Number of times' },
    },
  },
  formStep: {
    field: {
      form: { label: 'Form' },
      icon: { label: 'Icon' },
      optional: { label: ' (Optional)' },
    },
    perform: {
      between: 'Between {{min}} and {{max}}',
      actualQuantity: 'Actual ERP quantity: {{quantity}}',
      bestBeforePeriod: 'Best before (default): {{best_before_period}} days',
    },
  },
  splitStep: {
    field: {
      form: { label: 'Form' },
      icon: { label: 'Icoon' },
      newBatchQuantity: {
        label: 'New Batch Size',
        info: 'One container contains one batch with X amount of plants. X is in this case the batch size.',
      },
      newBatchVariableQuantity: {
        label: 'Variable',
        info: 'Quantity is an expected quantity, the real quantity can vary.',
      },
      newBatchVariableQuantityPredetermined: {
        label: 'Variable Predetermined',
        info: 'The variable quantity is predetermined, this enables the quantity to already affect the step itself for certain form fields and how much you need to scan.',
      },
      newBatchVariableUseOrderSize: {
        label: 'Use order size?',
        info: 'The expected quantity is variable, this enables the quantity field to have a default value of the order size.',
      },
      printer: { label: 'Printer' },
      template: { label: 'Template' },
      copies: { label: 'Default Number of Copies' },
      type: {
        label: 'Identification by',
        value: {
          scan: 'Loading Carrier',
          print: 'Print / Label',
          article: 'Article Identification',
          provided: 'Provided Serial Number',
        },
        tooltip: {
          scan: 'At identification by \'Loading Carrier\' the batch is paired with the scanned loading carrier.',
          print: 'At identification by \'Print/Label\' a label is printed according the set template. If you do not add form fields to this batch step, you can labels with the printall feature.',
          article:
            'At identification by \'Article Identifaction\' an article type should be scanned that is used in the process of identification.',
          provided: 'At identification by \'Provided Serial Number\' the serial number by which the batch should be identified is provided by the operator. This still has to match the new serial number format defined above.',
        },
      },
      defaultPrinter: {
        label: 'Default Printer',
        info: 'When printing, this printer will be selected by default if available.',
      },
    },
  },
  formStepField: {
    field: {
      label: { label: 'Label' },
      type: {
        label: 'Type',
        value: {
          text: 'Text',
          check: 'Check',
          choice: 'Choice',
          article_type: 'Article Type',
          measure: 'Measurement',
          format: 'Format',
          variable: 'Variable',
          image: 'Image',
          quantity: 'Quantity',
          bom: 'BOM',
          assembly_bom: 'Assembly BOM',
          storage_location: 'Storage Location',
          metafield: 'Metafield',
          material_plan_material: 'Material plan material',
          material_plan_task: 'Material plan task',
          sub_batches: 'Sub-batches',
          best_before_date: 'Best before date',
        },
      },
      target: { label: 'Details' },
      articleType: { label: 'Article Type' },
      materialPlan: {
        stockWarning: 'Warning! The stock in warehouse {{warehouse}} is only {{stock}}',
        tasks: 'Tasks',
        materials: 'Materials',
        notAvailable: 'Not available',
        printLabel: { label: 'Print label' },
        code: { label: 'Code' },
        description: { label: 'Description' },
        checklist: { label: 'Checklist' },
        requiredQuantity: { label: 'Required quantity' },
        required: { label: 'Required' },
        finished: { label: 'Finished' },
        backflush: { label: 'Backflush' },
        batchScan: { label: 'Batch scan' },
        number: { label: 'No.' },
        requiredForThisBatch: { label: 'Required for this batch' },
        issued: { label: 'Issued' },
        noWorkstationLinked: 'Not linked to a workstation',
        noTasks: 'No linked tasks',
        noMaterials: 'No linked materials',
        confirmDeletion: {
          header: 'You are trying to delete scanned batches for BOM item {{description}}!',
          content: 'Are you sure you want to proceed with this action?\n If yes then you should also revert issued materials manually in Exact!',
          confirmButton: 'DELETE',
        },
      },
      subBatches: {
        image: 'Image',
        articleCode: 'Article Code',
        articleName: 'Article Description',
        planned: '# planned',
        nested: '# nested',
        collected: '# collected',
        superProductionRequest: 'PR order ID',
      },
      slug: {
        label: 'Print Reference',
        info:
          'This value can be used in other print templates. Only characters A-Z, a-z, 0-9 en _ are allowed and the value cannot start with a number',
      },
      storageLocations: { label: 'Storage location(s) (Quantity)' }
    },
  },
  batch: {
    batchInfoModal: {
      trigger: 'BATCH INFO',
      noBatches: 'No batches available',
    },
    scanToPerformModal: {
      trigger: 'SCAN TO PERFORM',
    },
    overview: {
      title: 'Batch History',
      empty: 'No batches are found.',
      moreMetavalues: '{{count}} more metavalue(s)',
    },
    field: {
      batchType: { label: 'Type' },
      productionRequest: { label: 'Work ID' },
      productionOrder: { label: 'Production Order ID' },
      inShipment: { label: 'Receive ID' },
      outShipment: { label: 'Pick ID' },
      warehouseTransfer: { label: 'Transfer ID' },
      purchaseOrderNumberERP: { label: 'Purchase Order ERP' },
      salesOrderNumberERP: { label: 'Sales Order ERP' },
      productionOrderNumberERP: { label: 'Production Order ERP' },
      orderNumberERP: { label: 'ERP Order' },
      batchUseds: {
        label: 'Used by',
        empty: 'This batch has not been used in any other batches.',
      },
      batchUsings: {
        label: 'Used',
        empty: 'This batch has not used any other batches.',
      },
      details: {
        label: 'Details',
        empty: 'This product has no details.',
      },
      performances: {
        label: 'Performances',
        empty: 'This product has no performances.',
        description: '{{step}} performed by {{operator}} @ {{createdAt}}',
        printedDocument: 'Printed document',
      },
      scrapReason: { label: 'Scrap reason' },
      serialNumber: { label: 'Serial number' },
      finished: { label: 'Finished' },
      scrapped: { label: 'Rejected' },
      loadCarrier: { label: 'Loading carrier' },
      metavalues: { label: 'Metavalues' },
      quantityRemaining: { label: 'Quantity Remaining' },
      storageLocation: { label: 'Storage Location' },
      createdAt: { label: 'Created at' },
    },
    button: {
      rework: { label: 'Rework batch' },
      batchesView: { label: 'History file' },
      export: {
        label: 'Export batches',
        fileName: 'batch_export',
      },
    },
    filter: {
      dateCreated: 'Created At Date',
      dateFinished: 'Finished Date',
      dateReceived: 'Received Date',
      articleTypeCode: 'Article Code',
      articleTypeName: 'Article Name',
      details: 'Details',
      bomItems: 'Bom Articles'
    }
  },
  subassembly: {
    overview: {
      title: 'Sub products',
      empty: 'No sub products are found.',
    },
    field: {
      parentBatch: { label: 'Master product' },
      parentSubassembly: { label: 'Master sub product' },
      subassemblies: {
        label: 'Sub products',
        empty: 'This sub products has no other sub products.',
      },
      details: {
        label: 'Details',
        empty: 'This sub products has no details.',
      },
      performances: {
        label: 'Performances',
        empty: 'This sub product has no performances.',
        description: '{{step}} performed by {{operator}} @ {{createdAt}}',
      },
      serialNumber: { label: 'Serial number' },
      finished: { label: 'Done' },
    },
  },
  progress: {
    title: 'Progress overview',
    titleNests: 'Progress overview ({{open}} open) ({{inProgress}} in progress)',
    empty: 'There are no orders for this week.',
    processType: {
      label: 'Process Type',
      value: {
        make: 'Main Make',
        buy: 'Sub Inbound',
        sell: 'Sub Outbound',
        transfer_line: 'Sub Transfer line',
        stock_count: 'Main Stock Count',
        transfer: 'Main Transfer',
        pick_order: 'Main Pick Order',
        receive_order: 'Main Receive Order',
      },
    },
    productionLine: {
      stat: {
        todo: 'To do',
        inProgress: 'In progress',
        done: 'Done',
        operators_one: 'Operator',
        operators_other: 'Operators',
      },
    },
    weekPicker: 'Week',
    monthPicker: 'Month',
    dayPicker: 'Day',
    processVersion: 'Version{{version}}',
    groupBy: {
      label: 'Group by',
      value: {
        workStation: 'Work Station',
        step: 'Step',
      },
    },
    bulkActionButton: {
      choose: 'Choose bulk action ({{count}})'
    },
    nestingStepType: {
      label: 'Export/Import Step',
      export: 'Export step',
      import: 'Import step',
    },
  },
  detail: {
    field: {
      value: { label: 'Value' },
      reason: { label: 'Reason' },
    },
  },
  targetInfoModal: {
    title: 'History file',
    scrapped: 'Rejected: {{reason}}',
    variableQuantity: 'Variable Quantity',
    reprint: {
      title: 'Reprint',
      quantity: 'Number of Copies',
      printButton: 'Print',
    },
    materialPlanTaskField: {
      performed: 'Performed',
      amountRequired: 'Amount required',
      description: 'Description'
    },
    loadCarrier: { label: 'Scanned load carrier' },
  },
  serialNumberFormat: {
    part: {
      text: {
        label: 'Text',
        content: 'Content',
      },
      date: {
        label: 'Date',
        part: {
          label: 'Part',
          year: { label: 'Year' },
          month: {
            label: 'Month',
            text: 'Text',
            names: {
              january: {
                label: 'January',
                value: 'JAN',
              },
              february: {
                label: 'February',
                value: 'FEB',
              },
              march: {
                label: 'March',
                value: 'MAR',
              },
              april: {
                label: 'April',
                value: 'APR',
              },
              may: {
                label: 'May',
                value: 'MAY',
              },
              june: {
                label: 'June',
                value: 'JUN',
              },
              july: {
                label: 'July',
                value: 'JUL',
              },
              august: {
                label: 'August',
                value: 'AUG',
              },
              september: {
                label: 'September',
                value: 'SEP',
              },
              october: {
                label: 'Oktober',
                value: 'OKT',
              },
              november: {
                label: 'November',
                value: 'NOV',
              },
              december: {
                label: 'December',
                value: 'DEC',
              },
            },
          },
          day: { label: 'Day' },
          isoyear: { label: 'ISO Year' },
          isoweek: { label: 'ISO Week' },
          isoweekday: {
            label: 'ISO Weekday',
            text: 'Text',
            names: {
              monday: {
                label: 'Monday',
                value: 'Mo',
              },
              tuesday: {
                label: 'Tuesday',
                value: 'Tu',
              },
              wednesday: {
                label: 'Wednesday',
                value: 'We',
              },
              thursday: {
                label: 'Thursday',
                value: 'Th',
              },
              friday: {
                label: 'Friday',
                value: 'Fr',
              },
              saturday: {
                label: 'Saturday',
                value: 'Sa',
              },
              sunday: {
                label: 'Sunday',
                value: 'Su',
              },
            },
          },
        },
        format: 'Format',
      },
      code: {
        label: 'Code',
        alphabet: 'Alphabet',
        digits: 'Number',
        expand: 'Expands',
        defaultAlphabets: {
          decimal: 'Dec',
          binary: 'Bin',
          hexadecimalLower: 'Hex (a)',
          hexadecimalUpper: 'Hex (A)',
          octal: 'Oct',
          alphanumericLower: 'Alfanum (a)',
          alphanumericUpper: 'Alfanum (A)',
          alphabeticLower: 'Alfa (a)',
          alphabeticUpper: 'Alfa (A)',
          custom: 'Other, like:',
        },
      },
      anything: {
        label: 'Anything',
      },
      article_type: {
        label: 'Article Type Property',
        prop: {
          label: 'Property',
          code: 'Code',
          barcode: 'Barcode',
          metafield: 'Metafield',
        },
      },
    },
  },
  performanceResetModal: {
    title: 'Rework',
    reason: 'Reason',
    resetButton: 'Rework',
    createNewBatch: 'Create new batch',
    printButton: 'Rework and print label',
    carrierWarning: 'If this batch is reworked, shipping labels will be removed in Tracy. The related requests have to be manually reworked in the carrier.',
  },
  textPart: {
    field: {
      text: {
        placeholder: 'No text added',
      },
    },
  },
  imagePart: {
    field: {
      subtitle: {
        placeholder: 'No title',
      },
    },
  },
  metaPart: {
    field: {
      metafield: { label: 'Metafield' },
    },
  },
  printerSetup: {
    connectionError: 'Error when pairing the printer.',
    connectionNameError: 'Unknown printer name.',
    connectionPdfError: 'Error when pairing the label.',
    title: 'Printer Setup',
    installQzTray: {
      beforeLink: 'Install ',
      link: 'QZ Tray',
      afterLink: '.',
    },
    downloadCertificate: {
      beforeLink: 'Download ',
      link: 'this file',
      beforeLocation: ' and save it as ',
      afterLocation: '.',
    },
    editProperties: {
      beforeLocation: 'Add the next line to the end of the file ',
      afterLocation: ':',
    },
    testPrinter: {
      beforeButtons: 'Printer test:',
      printer: {
        altec: 'Altec',
        zebra: 'Zebra/Toshiba',
      },
    },
    printerModal: {
      title: 'Test Print',
      selectPrinter: 'Select Printer',
      noPrintersAvailable: 'No Printers Available',
      printButton: 'Print',
    },
  },
  workSlot: {
    field: {
      monday: { label: 'Mo' },
      tuesday: { label: 'Tu' },
      wednesday: { label: 'We' },
      thursday: { label: 'Th' },
      friday: { label: 'Fr' },
      saturday: { label: 'Sa' },
      sunday: { label: 'Su' },
      isBreak: { label: 'Is Break' },
    },
  },
  workCalendar: {
    scope: {
      type: {
        week: { label: 'Week' },
        month: { label: 'Month' },
      },
    },
    slot: {
      planned: 'Planned',
      overtime: 'Overtime',
      absent: 'Absent',
      break: 'Break',
    },
    leaveSlotModal: {
      title: {
        add: 'Add Leave Request',
        edit: 'Change Leave Request',
      },
    },
    workTimesModal: {
      title: 'Work Times {{scope}}',
    },
    total: {
      planned: '{{time}} planned',
      actual: '{{time}} worked',
      overtime: '{{time}} of overtime',
      absence: '{{time}} of absence',
    },
    timeBalance: {
      title: 'Time balance',
    },
  },
  leaveSlot: {
    field: {
      window: { label: 'Period' },
      fullDay: { label: 'Full Day' },
      name: { label: 'Reason' },
      user: { label: 'User' },
      operator: { label: 'Operator' },
      type: {
        label: 'Type',
        value: {
          vacation: 'Vacation',
          rwh: 'RWH',
          special: 'Special',
        },
        valueShort: {
          vacation: 'VAC',
          rwh: 'RWH',
          special: 'SPE',
        },
        valueMinutes: {
          vacation: 'Vacation',
          rwh: 'RWH',
          special: 'Special Leave',
        },
      },
      status: {
        label: 'Status',
        value: {
          approved: 'Approved',
          pending: 'Pending',
          rejected: 'Rejected',
        },
      },
      startDate: { label: 'Start Date' },
      endDate: { label: 'End Date' },
    },
    overview: {
      title: 'Leave Requests',
      noneFound: 'No leave requests have been found.',
      noneSelected: 'No leave request has been selected yet.',
      hasComments: 'Has comments',
      availabilityHeader: 'Available Operator Hours',
      statusAction: {
        approved: 'Approve',
        pending: 'Reconsider',
        rejected: 'Reject',
      },
    },
    edit: {
      comments: {
        empty: 'No comments have been placed yet.',
      },
      stats: {
        allowed: 'Allowed',
        used: 'Used',
        available: 'Available',
        requested: 'Requested',
      },
      days: {
        legend: {
          full: 'Full Availability',
          approved: 'When Approved',
          rejected: 'When Rejected',
        },
      },
      legal: 'Legal',
      extra: 'Extra',
    },
  },
  leaveCalendar: {
    overview: {
      title: 'Leave Calendars',
      empty: 'No leave calendars could be found.',
      addButton: 'Leave calendar',
    },
    create: {
      title: 'Create Leave Calendar',
    },
    edit: {
      title: 'Edit Leave Calendar',
      saveBeforeLeaveSlot: 'You must first save this leave calendar before you can add leave.',
    },
    field: {
      name: { label: 'Name' },
      operators: { label: 'Operators' },
      users: { label: 'Users' },
      leaveSlots: { label: 'Leave' },
    },
  },
  operatorCapability: {
    field: {
      level: {
        label: 'Level',
        value: {
          1: 'Not qualified',
          2: 'Trainee',
          3: 'Qualified',
          4: 'Expert',
        },
      },
    },
  },
  process: {
    overview: {
      title: 'Processes',
      addButton: 'Process',
      empty: 'No processes could be found.',
    },
    edit: {
      newBatch: {
        label: 'New Batch',
        info: 'Performing this step will create a new batch, these fields specify some details about this batch.',
      },
      versioned: {
        label: '(versioned)',
        info: 'This value only applies to the currently selected version.',
      },
      baseFields: {
        label: 'Basic fields',
        info: 'These values do not pair with a specific version but to all versions.',
      },
      batchSize: {
        label: 'Batch Size',
        value: {
          productionRequest: 'Order Size',
          unit: 'Unit',
          batch: 'Batch of {{count}}',
        },
        error: {
          notSmaller: 'The batch size should be smaller then the previous batch size.',
          notFinal: 'Batch size must be equal to {{expected}}.',
        },
      },
      title: 'Edit Process',
      version: 'Version {{version}}',
      finalized: {
        label: 'Finalized at: {{date}}',
        by: 'Finalized by: {{user}}',
      },
      updated: {
        label: 'Updated at: {{date}}',
        by: 'Updated by: {{user}}',
      },
      noUpdateInfo: 'No information available when this version has been updated.',
      draft: 'Concept',
      finalizeButton: 'Finalize',
      nextVersionButton: 'Next Version',
      noStepSelected: 'No step selected',
      noBatchTypeSelected: 'No process selected',
      workStationsModal: {
        title: 'Select Work Stations',
      },
      tabs: {
        steps: 'Steps',
        instructions: 'Instructions',
        articleTypes: 'Article Types',
      },
      addArticleTypeModal: {
        title: 'Add Article Types',
        addButton: 'Add ({{count}})',
      },
      harvestDay: {
        monday: 'Mo',
        tuesday: 'Tu',
        wednesday: 'We',
        thursday: 'Th',
        friday: 'Fr',
        saturday: 'Sa',
        sunday: 'Su',
      },
    },
    create: {
      title: 'Create Process',
    },
    field: {
      name: { label: 'Name' },
      newestVersion: { label: 'Latest version' },
    },
  },
  processVersion: {
    field: {
      productionLineVersion: { label: 'Production Area' },
      batchType: { label: 'Process' },
      version: { label: 'Version' },
      newest: { label: 'Latest' },
      factory: { label: 'Factory' },
    },
  },
  dataSource: {
    edit: {
      title: 'Edit Data Source',
    },
    create: {
      title: 'Create Data Source',
    },
    overview: {
      title: 'Data Sources',
      empty: 'No data sources are linked yet.',
    },
    field: {
      uuid: { label: 'UUID' },
      identifier: { label: 'Identification Code' },
      name: { label: 'Name' },
      deviceName: { label: 'Name of Source' },
      description: { label: 'Description' },
      workStations: { label: 'Paired Work Stations' },
      data: {
        label: 'Data',
        name: 'Name',
        type: 'Type',
      },
    },
  },
  exactShopOrder: {
    field: {
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Released',
          in_progress: 'In progress',
          finalized: 'Done',
          complete: 'Finalized',
        },
      },
    },
  },
  loadCarrier: {
    overview: {
      title: 'Loading carriers',
      empty: 'No loading carriers that could be found.',
      addButton: 'Loading carrier',
      batchAddButton: 'Batch Add',
    },
    create: {
      title: 'Add Loading Carrier',
    },
    edit: {
      title: 'Edit Loading Carrier',
      weighing: '{{weight}}{{unit}}, weighed by {{user}} at {{time}}.',
      unknownUser: 'unknown',
      batchErrors: {
        notFound: 'No batch found with this serial number.',
        alreadyUsed: 'Batch with this serial number is used on another load carrier, with serial number "{{serial_number}}".',
        deleted: 'This load carrier was deleted',
      },
    },
    batchAdd: {
      title: 'Batch Add Loading Carriers',
      quantity: 'Quantity',
      serialNumberFormat: 'Serial Number Format',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          pallet_euro: 'Euro pallet',
          pallet_pool: 'Pool pallet',
          container: 'Container',
          gitterbox: 'Gitterbox',
          carton_box: 'Box',
          stackbox: 'Stackable box',
        },
      },
      serialNumber: { label: 'Serial Number' },
      name: { label: 'Name' },
      description: { label: 'Description' },
      weight: { label: 'Weight' },
      width: { label: 'Width' },
      height: { label: 'Height' },
      length: { label: 'Length' },
      volume: { label: 'Volume' },
      picture: { label: 'Picture' },
      batch: { label: 'Batch' },
      hasBatch: { label: 'Has Batch' },
      batchCreatedAt: { label: 'Batch Created At' },
      weighings: { label: 'Weighings' },
      reweighDays: {
        label: 'Weighing Interval',
        info: 'Here you can specify how often the loading carrier needs to be weighed. In the loading carrier overview you can then see when the loading carrier needs to be weighed again and filter on loading carriers that needs to be weighed.',
        suffix: ' days',
      },
      reweighDate: { label: 'Reweigh Date' },
      needsReweighing: { label: 'Needs Reweighing' },
      linkedOrders: {
        label: 'Linked orders',
        labelErp: 'Linked ERP orders',
      },
      deliveryDetails: { label: 'Delivery details' },
    },
  },
  login: {
    workstation: 'Workstation Code',
    barcode: 'Article Barcode',
    title: 'Tracy Picker',
    username: 'Username',
    batchcode: 'Batchcode',
    notAllowed: 'This is not a correct workstation code',
    password: 'Password',
    forgotPassword: 'Forgot your password?',
    clearusername: 'Clear username',
    clearworkstation: 'Erase workstation code',
    clearprinter: 'Erase printer pairing info',
    forgotMailSend: 'Send email to reset password',
    successful: 'Successfully logged in with badge ID',
    logIn: 'Log In',
    error: 'Error while logging in',
    wrong_credentials: 'Unrecognized batchcode',
  },
  shipping: {
    picture: 'Picture of the cargo',
    picture_accept: 'Accept picture',
    picture_decline: 'Re-take',
    packed: 'Finish packing article',
    start_send: 'Start shipping process',
    start_receive: 'Start receiving process',
    send_shipment: 'Send this shipment',
    receive_shipment: 'Accept receipt',
  },
  articleInfo: {
    clearBarcode: 'Erase barcode',
    search: 'Search for barcode',
    wrongCode: 'Barcode not available',
    notAllowed: 'This user is not allowed to have access to this info',
    error: 'Error while searching',
  },
  shipment: {
    articleInfo: {
      performanceLine:
        '{{stepLabel}} of process {{productionLineName}} performed @ {{performanceTime}} by {{performanceBy}}',
      batchType: 'Batch Type',
      batchDescription: 'Batch Description',
      batchSize: 'Batch Size',
      articleNameAndCode: 'Article Name & Code',
      scannedBarcode: 'Scanned Barcode',
    },
    picker: {
      default: 'Select a value',
    },
    shipmentInfo: 'Shipment Information',
    status: {
      picking: 'Picking',
      printing: 'Printing',
      sending: 'Sending',
      sent: 'Shipped',
      toReceive: 'To Receive',
      received: 'Received and Processed',
    },
    field: {
      salesOrder: {
        label: 'Sales Order',
      },
      purchaseOrder: { label: 'Purchase Order' },
      receiveDate: { label: 'Planned Receive Date' },
      receivedTime: { label: 'Receive Time' },
      shippingDate: {
        label: 'Shipping date',
      },
      freightType: {
        label: 'Typ of Cargo',
        value: {
          road: 'Land',
        },
      },
      freightCompany: {
        label: 'Freight Company',
      },
      shippingName: {
        label: 'Driver',
      },
      shippingLicense: {
        label: 'License Plate',
      },
      shippingTime: {
        label: 'Time of Shipment',
      },
      quantity: {
        label: 'Quantity',
      },
      batchSize: {
        label: 'Batch Size',
      },
    },
    outbound: {
      pickitem: 'Pick Items',
      printreceipt: 'Print Receive note',
      package: 'Packing',
      printshipmentlabel: 'Print shipment label',
      send: 'Ship',
    },
    inbound: {
      receiveItem: 'Receive Order line',
      barcodeCreation: 'Identify products',
      warehousePlacement: 'Add to stock location',
    },
    heading: {
      cmrLines: 'CMR LINES',
    },
    titleInbound: 'Inbound shipment {{id}}',
    titleOutbound: 'Outbound shipment {{id}}',
    confirmShipment: 'Weet je zeker dat je {{custName}} een zending wilt sturen met trackcode {{trackingNumber}}?',
    confirmReceive:
      'Weet je zeker dat je van {{custName}} een zending wilt accepteren met trackcode {{trackingNumber}}?',
    sendShipmentTitle: 'Send',
    receiveShipmentTitle: 'Receive',
    scan: 'Scan',
    leftToPick: 'To Pick',
    actualyPicked: 'Picked',
    documents: {
      label: 'Documents',
      packingListRequired: 'Packing List',
      invoiceRequired: 'Invoice',
      eur1Required: 'EUR1',
      cvoRequired: 'C/O',
    },
    tab: {
      shipmentLines: 'Articles',
      info: 'Info',
    },
    noCmrLines: 'This shipment does not have any CMR Lines.',
    box: 'Box {{id}}',
    buyBox: 'Box',
    hiddenBoxes: '{{count}} boxes',
    error: {
      wrongArticle: 'Wrong article.',
      alreadyScanned: 'Box already scanned.',
      alreadyShipped: 'Box already included in another pickorder.',
      tooManyProducts: 'Box contains too many products.',
      invalidQuantity: 'Invalid value for quantity.',
    },
  },
  common: {
    article: {
      field: {
        code: { label: 'Code' },
        name: { label: 'Name' },
      },
    },
    shipmentLine: {
      field: {
        quantity: { label: 'Quantity' },
      },
    },
    shipment: {
      field: {
        salesOrder: { label: 'Sales Order' },
        purchaseOrder: { label: 'Purchase Order' },
        shippingDate: { label: 'Shipping Date' },
        receiveDate: { label: 'Planned Delivery Date' },
        shippingName: { label: 'Shipper Name' },
        shippingTime: { label: 'Shipped Time' },
        receivedTime: { label: 'Delivery Time' },
        shippingLicense: { label: 'Shipper License Plate' },
        freight: {
          label: 'Freight',
          value: {
            road: 'Road',
            air: 'Air',
            sea: 'Sea',
            parcel: 'Parcel',
          },
        },
        freightCompany: { label: 'Freight Company' },
      },
    },
    cmrLine: {
      field: {
        mark: { label: 'Mark' },
        number: { label: 'Number' },
        method: { label: 'Method' },
        nature: { label: 'Nature' },
        weight: { label: 'Weight' },
        cubage: { label: 'Cubage' },
      },
    },
    loading: 'Loading...',
    error: {
      somethingWentWrong: 'Something went wrong.',
    },
  },
  workTime: {
    field: {
      date: { label: 'Date' },
      startTime: { label: 'Start Time' },
      endTime: { label: 'End Time' },
      automaticallyClockedOut: { label: 'Clocked-out automatically' },
    },
  },
  softdeleted: {
    label: 'Show deleted items',
    value: {
      true: 'yes',
      false: 'no',
      only: 'only',
    },
  },
  logistics: {
    erpCounts: {
      header: {
        date: 'Date',
        quantity: '#',
      },
    },
  },
  extraLeaveBalance: {
    field: {
      year: { label: 'Applicable to (year)' },
      legalLeaveMinutes: { label: 'Legal' },
      extraLeaveMinutes: { label: 'Extra' },
      rwhMinutes: { label: 'RWH' },
    },
  },
  formField: {
    name: { label: 'Form Fields' },
    field: {
      slug: { label: 'Print slug' },
      articleType: {
        self: '(Dynamic)',
        selfInfo: 'This will automatically fill in the Article Type where you\'re using this template.',
      },
      type: {
        quantityInfo: 'This type can be used to set a precise quantity on a variable batch. You can also enter a weight per unit, in this case the quantity will be based on a weight that the operator enters.',
        bestBeforePeriodInfo: 'This type can be used to store an expiration date. The best before date will then be the production date plus a number of days specified by the "best before period".',
      },
      measureMin: { label: 'Minimum' },
      measureMax: { label: 'Maximum' },
      quantityUnitWeight: { label: 'Weight per Unit' },
      articleTypeBatchSize: { label: 'Batch size?' },
      required: { label: 'Required' },
      scanConstraints: {
        add: 'Add a scan constraint.',
      },
      bestBefore: {
        period: 'Best before period',
        date: 'Best before date',
        remaining: 'Best before date remaining days',
      },
    },
  },
  scanConstraint: {
    field: {
      operator: {
        value: {
          eq: 'Equals',
          neq: 'Does not equal',
          lt: 'Less than',
          lte: 'Less than or equal to',
          gt: 'Greater than',
          gte: 'Greater than or equal to',
        },
      },
    },
  },
  integration: {
    overview: {
      title: 'Integrations',
      empty: 'No integrations found.',
      clearSyncErrorButton: 'Clear Sync Errors',
      xmlUploadButton: 'Upload assembly bom as xml file',
      addButton: 'Integration',
      inactive: 'This integration is not active.',
      XmlUploadModal: {
        title: 'Upload assembly bom as xml file',
        confirmButton: 'Upload',
      },
      SyncFromScratchModal: {
        title: 'Sync exact from scratch',
        warningHead: 'You are about to sync from scratch these topics:',
        warningTail: 'Make sure you only select the topics that you really want to sync from scratch. Syncing topics that require requesting a lot of data from Exact Online will result in a high sync request usage.',
        confirmButton: 'Sync',
      },
      pushWebhookUpdatesButton: 'Push Webhook Updates Now',
    },
    create: {
      title: 'Add Integration',
    },
    edit: {
      title: 'Edit Integration',
    },
    field: {
      type: {
        label: 'Type',
        value: {
          label: 'Type of integration',
          exact: 'Exact Online',
          unit4: 'Unit 4',
          navision: 'Navision',
          exact_globe: 'Exact Globe',
          transmission: 'TransMission',
          jan_krediet: 'Jan Krediet',
          ups: 'UPS',
          radan: 'Radan',
        },
      },
      id: { label: 'ID' },
      name: { label: 'Name' },
      apiRequestsRemaining: { label: 'API requests remaining' },
      apiRequestsRemainingDay: { label: 'today' },
      apiRequestsRemainingMinute: { label: 'this minute' },
      lastPerformedAt: { label: 'Last Performed At' },
      pendingPerformances: { label: 'Pending performances' },
      syncErrorCount: { label: 'Sync Errors' },
      webhookBacklogCount: { label: 'Webhook Updates Backlog' },
    },
    syncmodal: {
      title: 'Synchronize',
      syncButton: 'Sync',
      pending: 'Pending',
      from_db: 'Cached',
      from_inline: 'Partial',
      created: 'Created',
      updated: 'Updated',
      requested: 'Requested',
      dependencies: 'Dependencies',
      measureMin: { label: 'Min' },
      measureMax: { label: 'Max' },
      quantityUnitWeight: { label: 'Unit Weight' },
    },
    syncers: {
      exact_account_syncer: 'Accounts',
      exact_address_syncer: 'Addresses',
      exact_item_syncer: 'Items',
      exact_item_extra_field_syncer: 'Item Extra Fields',
      exact_project_syncer: 'Projects',
      exact_purchase_order_syncer: 'Purchase Orders',
      exact_sales_order_syncer: 'Sales Orders',
      exact_warehouse_syncer: 'Warehouses',
      exact_storage_location_syncer: 'Storage Locations',
      exact_item_warehouse_syncer: 'Stocks (per Warehouse)',
      exact_item_warehouse_location_syncer: 'Stocks (per Location)',
      exact_goods_receipt_syncer: 'Goods Receipt',
      exact_warehouse_transfer_syncer: 'Warehouse Transfers',
      exact_stock_position_syncer: 'Stock Updates',
      exact_shipping_method_syncer: 'Shipping Methods',
      exact_document_syncer: 'Documents',
    },
  },
  exactIntegration: {
    overview: {
      authButton: 'Activate Exact Integration',
      authResult: {
        ok: 'Exact integration activated successfully.',
        error: {
          invalid_request: 'Invalid request sent to Exact.',
          invalid_client: 'Invalid client sent to Exact.',
          invalid_grant: 'Invalid grant sent to Exact.',
          invalid_scope: 'Invalid scope sent to Exact. ',
          unauthorized_client: 'Unauthorized client sent to Exact.',
          unsupported_grant_type: 'Unsupported grant type sent to Exact.',
        },
      },
      syncButton: 'Sync with Exact',
      syncFromScratchButton: 'Sync from scratch',
      syncResult: {
        authFailure: { notification: 'Authentication to Exact failed.' },
        success: {
          notification: 'Successfully synced with Exact',
          count: {
            address: {
              tracy: {
                created_one: 'Created {{count}} Address in Tracy.',
                created_other: 'Created {{count}} Addresses in Tracy.',
                updated_one: 'Updated {{count}} Address in Tracy.',
                updated_other: 'Updated {{count}} Addresses in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Address in Exact.',
                created_other: 'Created {{count}} Addresses in Exact.',
                updated_one: 'Updated {{count}} Address in Exact.',
                updated_other: 'Updated {{count}} Addresses in Exact.',
              },
            },
            item: {
              tracy: {
                created_one: 'Created {{count}} Item in Tracy.',
                created_other: 'Created {{count}} Items in Tracy.',
                updated_one: 'Updated {{count}} Item in Tracy.',
                updated_other: 'Updated {{count}} Items in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Item in Exact.',
                created_other: 'Created {{count}} Items in Exact.',
                updated_one: 'Updated {{count}} Item in Exact.',
                updated_other: 'Updated {{count}} Items in Exact.',
              },
            },
            project: {
              tracy: {
                created_one: 'Created {{count}} Project in Tracy.',
                created_other: 'Created {{count}} Projects in Tracy.',
                updated_one: 'Updated {{count}} Project in Tracy.',
                updated_other: 'Updated {{count}} Projects in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Project in Exact.',
                created_other: 'Created {{count}} Projects in Exact.',
                updated_one: 'Updated {{count}} Project in Exact.',
                updated_other: 'Updated {{count}} Projects in Exact.',
              },
            },
            storage_location_stock_position: {
              tracy: {
                created_one: 'Created {{count}} Storage Location Stock Position in Tracy.',
                created_other: 'Created {{count}} Storage Location Stock Positions in Tracy.',
                updated_one: 'Updated {{count}} Storage Location Stock Position in Tracy.',
                updated_other: 'Updated {{count}} Storage Location Stock Positions in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Storage Location Stock Position in Exact.',
                created_other: 'Created {{count}} Storage Location Stock Positions in Exact.',
                updated_one: 'Updated {{count}} Storage Location Stock Position in Exact.',
                updated_other: 'Updated {{count}} Storage Location Stock Positions in Exact.',
              },
            },
            bill_of_material: {
              tracy: {
                created_one: 'Created {{count}} Bill Of Material in Tracy.',
                created_other: 'Created {{count}} Bill Of Materials in Tracy.',
                updated_one: 'Updated {{count}} Bill Of Material in Tracy.',
                updated_other: 'Updated {{count}} Bill Of Materials in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Bill Of Material in Exact.',
                created_other: 'Created {{count}} Bill Of Materials in Exact.',
                updated_one: 'Updated {{count}} Bill Of Material in Exact.',
                updated_other: 'Updated {{count}} Bill Of Material in Exact.',
              },
            },
            bill_of_material_item: {
              tracy: {
                created_one: 'Created {{count}} Bill Of Material Item in Tracy.',
                created_other: 'Created {{count}} Bill Of Material Items in Tracy.',
                updated_one: 'Updated {{count}} Bill Of Material Item in Tracy.',
                updated_other: 'Updated {{count}} Bill Of Material Items in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Bill Of Material Item in Exact.',
                created_other: 'Created {{count}} Bill Of Material Items in Exact.',
                updated_one: 'Updated {{count}} Bill Of Material Item in Exact.',
                updated_other: 'Updated {{count}} Bill Of Material Items in Exact.',
              },
            },
            account: {
              tracy: {
                created_one: 'Created {{count}} Account in Tracy.',
                created_other: 'Created {{count}} Accounts in Tracy.',
                updated_one: 'Updated {{count}} Account in Tracy.',
                updated_other: 'Updated {{count}} Accounts in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Account in Exact.',
                created_other: 'Created {{count}} Accounts in Exact.',
                updated_one: 'Updated {{count}} Account in Exact.',
                updated_other: 'Updated {{count}} Accounts in Exact.',
              },
            },
            sales_order: {
              tracy: {
                created_one: 'Created {{count}} Sales Order in Tracy.',
                created_other: 'Created {{count}} Sales Orders in Tracy.',
                updated_one: 'Updated {{count}} Sales Order in Tracy.',
                updated_other: 'Updated {{count}} Sales Orders in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Sales Order in Exact.',
                created_other: 'Created {{count}} Sales Orders in Exact.',
                updated_one: 'Updated {{count}} Sales Order in Exact.',
                updated_other: 'Updated {{count}} Sales Orders in Exact.',
              },
            },
            purchase_order: {
              tracy: {
                created_one: 'Created {{count}} Purchase Order in Tracy.',
                created_other: 'Created {{count}} Purchase Orders in Tracy.',
                updated_one: 'Updated {{count}} Purchase Order in Tracy.',
                updated_other: 'Updated {{count}} Purchase Orders in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Purchase Order in Exact.',
                created_other: 'Created {{count}} Purchase Orders in Exact.',
                updated_one: 'Updated {{count}} Purchase Order in Exact.',
                updated_other: 'Updated {{count}} Purchase Orders in Exact.',
              },
            },
            shop_order: {
              tracy: {
                created_one: 'Created {{count}} Production Order in Tracy.',
                created_other: 'Created {{count}} Production Orders in Tracy.',
                updated_one: 'Updated {{count}} Production Order in Tracy.',
                updated_other: 'Updated {{count}} Production Orders in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Production Order in Exact.',
                created_other: 'Created {{count}} Production Orders in Exact.',
                updated_one: 'Updated {{count}} Production Order in Exact.',
                updated_other: 'Updated {{count}} Production Orders in Exact.',
              },
            },
            shop_order_material_plan: {
              tracy: {
                created_one: 'Created {{count}} Production Order Material Plan in Tracy.',
                created_other: 'Created {{count}} Production Order Material Plans in Tracy.',
                updated_one: 'Updated {{count}} Production Order Material Plan in Tracy.',
                updated_other: 'Updated {{count}} Production Order Material Plans in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Production Order Material Plan in Exact.',
                created_other: 'Created {{count}} Production Order Material Plans in Exact.',
                updated_one: 'Updated {{count}} Production Order Material Plan in Exact.',
                updated_other: 'Updated {{count}} Production Order Material Plans in Exact.',
              },
            },
            shop_order_receipt: {
              tracy: {
                created_one: 'Created {{count}} Production Order Receipt in Tracy.',
                created_other: 'Created {{count}} Production Order Receipts in Tracy.',
                updated_one: 'Updated {{count}} Production Order Receipt in Tracy.',
                updated_other: 'Updated {{count}} Production Order receipts in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Production Order Receipt in Exact.',
                created_other: 'Created {{count}} Production Order Receipts in Exact.',
                updated_one: 'Updated {{count}} Production Order Receipt in Exact.',
                updated_other: 'Updated {{count}} Production Order Receipts in Exact.',
              },
            },
            shop_order_reversal: {
              tracy: {
                created_one: 'Created {{count}} Production Order Reversal in Tracy.',
                created_other: 'Created {{count}} Production Order Reversals in Tracy.',
                updated_one: 'Updated {{count}} Production Order Reversal in Tracy.',
                updated_other: 'Updated {{count}} Production Order Reversals in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Production Order Reversal in Exact.',
                created_other: 'Created {{count}} Production Order Reversals in Exact.',
                updated_one: 'Updated {{count}} Production Order Reversal in Exact.',
                updated_other: 'Updated {{count}} Production Order Reversals in Exact.',
              },
            },
            opportunity: {
              tracy: {
                created_one: 'Created {{count}} Opportunity in Tracy.',
                created_other: 'Created {{count}} Opportunities in Tracy.',
                updated_one: 'Updated {{count}} Opportunity in Tracy.',
                updated_other: 'Updated {{count}} Opportunities in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Opportunity in Exact.',
                created_other: 'Created {{count}} Opportunities in Exact.',
                updated_one: 'Updated {{count}} Opportunity in Exact.',
                updated_other: 'Updated {{count}} Opportunities in Exact.',
              },
            },
            quotation: {
              tracy: {
                created_one: 'Created {{count}} Quotation in Tracy.',
                created_other: 'Created {{count}} Quotations in Tracy.',
                updated_one: 'Updated {{count}} Quotation in Tracy.',
                updated_other: 'Updated {{count}} Quotations in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Quotation in Exact.',
                created_other: 'Created {{count}} Quotations in Exact.',
                updated_one: 'Updated {{count}} Quotation in Exact.',
                updated_other: 'Updated {{count}} Quotations in Exact.',
              },
            },
            goods_receipt: {
              tracy: {
                created_one: 'Created {{count}} Goods Receipt in Tracy.',
                created_other: 'Created {{count}} Goods Receipts in Tracy.',
                updated_one: 'Updated {{count}} Goods Receipt in Tracy.',
                updated_other: 'Updated {{count}} Goods Receipts in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Goods Receipt in Exact.',
                created_other: 'Created {{count}} Goods Receipts in Exact.',
                updated_one: 'Updated {{count}} Goods Receipt in Exact.',
                updated_other: 'Updated {{count}} Goods Receipts in Exact.',
              },
            },
            goods_delivery: {
              tracy: {
                created_one: 'Created {{count}} Goods Delivery in Tracy.',
                created_other: 'Created {{count}} Goods Deliveries in Tracy.',
                updated_one: 'Updated {{count}} Goods Delivery in Tracy.',
                updated_other: 'Updated {{count}} Goods Deliveries in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Goods Delivery in Exact.',
                created_other: 'Created {{count}} Goods Deliveries in Exact.',
                updated_one: 'Updated {{count}} Goods Delivery in Exact.',
                updated_other: 'Updated {{count}} Goods Deliveries in Exact.',
              },
            },
            sales_return: {
              tracy: {
                created_one: 'Created {{count}} Sales Return in Tracy.',
                created_other: 'Created {{count}} Sales Returns in Tracy.',
                updated_one: 'Updated {{count}} Sales Return in Tracy.',
                updated_other: 'Updated {{count}} Sales Returns in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} sales return in Exact.',
                created_other: 'Created {{count}} sales returns in Exact.',
                updated_one: 'Updated {{count}} sales return in Exact.',
                updated_other: 'Updated {{count}} sales returns in Exact.',
              },
            },
            warehouse_transfer: {
              tracy: {
                created_one: 'Created {{count}} Warehouse Transfer in Tracy.',
                created_other: 'Created {{count}} Warehouse Transfers in Tracy.',
                updated_one: 'Updated {{count}} Warehouse Transfer in Tracy.',
                updated_other: 'Updated {{count}} Warehouse Transfers in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Warehouse Transfer in Exact.',
                created_other: 'Created {{count}} Warehouse Transfers in Exact.',
                updated_one: 'Updated {{count}} Warehouse Transfer in Exact.',
                updated_other: 'Updated {{count}} Warehouse Transfers in Exact.',
              },
            },
            stock_count: {
              exact: {
                created_one: 'Created {{count}} Stock Count in Exact.',
                created_other: 'Created {{count}} Stock Counts in Exact.',
              },
            },
            shipping_method: {
              tracy: {
                created_one: 'Created {{count}} shipping method in Tracy.',
                created_other: 'Created {{count}} shipping methods in Tracy.',
                updated_one: 'Updated {{count}} shipping method in Tracy.',
                updated_other: 'Updated {{count}} shipping methods in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} shipping method in Exact.',
                created_other: 'Created {{count}} shipping methods in Exact.',
                updated_one: 'Updated {{count}} shipping method in Exact.',
                updated_other: 'Updated {{count}} shipping methods in Exact.',
              },
            },
            document: {
              exact: {
                created_one: 'Created {{count}} document in Exact.',
                created_other: 'Created {{count}} documents in Exact.',
                updated_one: 'Updated {{count}} document in Exact.',
                updated_other: 'Updated {{count}} documents in Exact.',
              },
            },
          },
        },
      },
    },
    field: {
      division: { label: 'Division' },
      webhooks: { label: 'Webhooks' },
      address: { label: 'Address' },
      item: { label: 'Item' },
      itemExtraField: { label: 'Item Extra Field' },
      manageEndpointsHeader: { label: 'Manage Exact API Endpoints' },
      manageWebhooksHeader: { label: 'Manage Exact Webhooks Topics' },
      account: { label: 'Account' },
      opportunity: { label: 'Opportunity' },
      salesOrder: { label: 'Sales Order' },
      purchaseOrder: { label: 'Purchase Order' },
      project: { label: 'Project' },
      billOfMaterial: { label: 'Bill Of Material' },
      billOfMaterialItem: { label: 'Bill Of Material Item' },
      shopOrder: { label: 'Shop Order' },
      shopOrderReceipt: { label: 'Shop Order Receipt' },
      shopOrderReversal: { label: 'Shop Order Reversal' },
      salesReturn: { label: 'Sales Return' },
      warehouse: { label: 'Warehouse' },
      storageLocationStockPosition: { label: 'Storage Location Stock Position' },
      itemWarehouse: { label: 'Item Warehouse' },
      warehouseTransfer: { label: 'Warehouse Transfer' },
      goodsReceipt: { label: 'Goods Receipt' },
      goodsDelivery: { label: 'Goods Delivery' },
      stockCount: { label: 'Stock Count' },
      stockCountReversal: { label: 'Stock Count Reversal' },
      stockTopic: { label: 'Stock' },
      stockSerialNumber: { label: 'Stock Serial Number' },
      shippingMethod: { label: 'Shipping Method' },
      document: { label: 'Document' },
      documentAttachment: { label: 'Document Attachment' },
      salesOrderWebhookTopic: { label: 'Sales Order Webhook' },
      dropShipment: { label: 'Drop Shipment' },
      byproductReceipt: { label: 'By product Receipt' },
      byproductReversal: { label: 'By product Reversal' },
      workcenter: { label: 'Workcenter' },
      clientId: { label: 'Client ID' },
      clientSecret: { label: 'Client Secret' },
    },
    edit: {
      topics: {
        title: 'Manage Exact API Endpoints',
        subtitle: {
          webhooks: 'Webhooks',
          general: 'General',
          production: 'Production',
          warehouse: 'Warehouse',
        },
      },
    },
  },
  exactGlobeIntegration: {
    overview: {
      authButton: 'Activate Exact Globe Integration',
      authResult: {
        ok: 'Exact globe integration activated successfully.',
        error: {
          invalid_request: 'Invalid request sent to Exact Globe.',
          invalid_client: 'Invalid client sent to Exact Globe.',
          invalid_grant: 'Invalid grant sent to Exact Globe.',
          invalid_scope: 'Invalid scope sent to Exact Globe. ',
          unauthorized_client: 'Unauthorized client sent to Exact Globe.',
          unsupported_grant_type: 'Unsupported grant type sent to Exact Globe.',
        },
      },
      syncButton: 'Sync with Exact Globe',
      syncResult: {
        authFailure: { notification: 'Authentication to Exact Globe failed.' },
        success: {
          notification: 'Successfully synced with Exact Globe',
          count: {
            item: {
              tracy: {
                created_one: 'Created {{count}} Item in Tracy.',
                created_other: 'Created {{count}} Items in Tracy.',
                updated_one: 'Updated {{count}} Item in Tracy.',
                updated_other: 'Updated {{count}} Items in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Item in Exact Globe.',
                created_other: 'Created {{count}} Items in Exact Globe.',
                updated_one: 'Updated {{count}} Item in Exact Globe.',
                updated_other: 'Updated {{count}} Items in Exact Globe.',
              },
            },
            account: {
              tracy: {
                created_one: 'Created {{count}} Account in Tracy.',
                created_other: 'Created {{count}} Accounts in Tracy.',
                updated_one: 'Updated {{count}} Account in Tracy.',
                updated_other: 'Updated {{count}} Accounts in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Account in Exact Globe.',
                created_other: 'Created {{count}} Accounts in Exact Globe.',
                updated_one: 'Updated {{count}} Account in Exact Globe.',
                updated_other: 'Updated {{count}} Accounts in Exact Globe.',
              },
            },
            sales_order: {
              tracy: {
                created_one: 'Created {{count}} Sales Order in Tracy.',
                created_other: 'Created {{count}} Sales Orders in Tracy.',
                updated_one: 'Updated {{count}} Sales Order in Tracy.',
                updated_other: 'Updated {{count}} Sales Orders in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Sales Order in Exact Globe.',
                created_other: 'Created {{count}} Sales Orders in Exact Globe.',
                updated_one: 'Updated {{count}} Sales Order in Exact Globe.',
                updated_other: 'Updated {{count}} Sales Orders in Exact Globe.',
              },
            },
            purchase_order: {
              tracy: {
                created_one: 'Created {{count}} Purchase Order in Tracy.',
                created_other: 'Created {{count}} Purchase Orders in Tracy.',
                updated_one: 'Updated {{count}} Purchase Order in Tracy.',
                updated_other: 'Updated {{count}} Purchase Orders in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Purchase Order in Exact Globe.',
                created_other: 'Created {{count}} Purchase Orders in Exact Globe.',
                updated_one: 'Updated {{count}} Purchase Order in Exact Globe.',
                updated_other: 'Updated {{count}} Purchase Orders in Exact Globe.',
              },
            },
            warehouse: {
              tracy: {
                created_one: 'Created {{count}} Warehouse in Tracy.',
                created_other: 'Created {{count}} Warehouse in Tracy.',
                updated_one: 'Updated {{count}} Warehouse in Tracy.',
                updated_other: 'Updated {{count}} Warehouse in Tracy.',
              },
              exact: {
                created_one: 'Created {{count}} Warehouse in Exact Globe.',
                created_other: 'Created {{count}} Warehouse in Exact Globe.',
                updated_one: 'Updated {{count}} Warehouse in Exact Globe.',
                updated_other: 'Updated {{count}} Warehouse in Exact Globe.',
              },
            },
          },
        },
      },
    },
    field: {
      baseUrl: { label: 'Base Url' },
      serverName: { label: 'Server Name' },
      databaseName: { label: 'Database Name' },
      username: { label: 'Username' },
      password: { label: 'Password' },
      manageEndpointsHeader: { label: 'Manage Exact Globe API Endpoints' },
      itemTopic: { label: 'Item' },
      accountTopic: { label: 'Account' },
      salesOrderTopic: { label: 'Sales Order' },
      purchaseOrderTopic: { label: 'Purchase Order' },
      warehouseTopic: { label: 'Warehouse' },
      productionTopic: { label: 'Production Order' },
    },
  },
  unit4Integration: {
    overview: {
      authButton: 'Activate Unit4 Integration',
      authResult: {
        ok: 'Unit4 integration activated successfully.',
        error: {
          invalid_request: 'Invalid request sent to Unit4.',
          invalid_client: 'Invalid client sent to Unit4.',
          invalid_grant: 'Invalid grant sent to Unit4.',
          invalid_scope: 'Invalid scope sent to Unit4. ',
          unauthorized_client: 'Unauthorized client sent to Unit4.',
          unsupported_grant_type: 'Unsupported grant type sent to Unit4.',
        },
      },
      syncButton: 'Sync with Unit4',
      syncResult: {
        authFailure: { notification: 'Authentication to Exact failed.' },
        success: {
          notification: 'Successfully synced with Exact',
        },
      },
    },
    field: {
      administration: { label: 'Administration code' },
      baseUrl: { label: 'Unit 4 server base URL' },
      manageEndpointsHeader: { label: 'Manage Unit4 API-endpoints' },
      goodsReceipt: { label: 'Goods Receipt' },
      product: { label: 'Product' },
      productDetails: { label: 'Product Detail' },
      supplier: { label: 'Supplier' },
      purchaseOrder: { label: 'Purchase Order' },
      customer: { label: 'Customer' },
      order: { label: 'Order' },
    },
  },
  syncError: {
    overview: {
      title: 'Sync Errors',
      empty: 'No sync errors found.',
    },
    field: {
      id: { label: 'ID' },
      message: { label: 'Message' },
      createdAt: { label: 'Created At' },
    },
  },
  exactSyncRequest: {
    overview: {
      title: 'Exact Integration Last Performed At',
      empty: 'No integrations found',
      exportButton: 'Export sync requests'
    },
    field: {
      id: {
        label: 'ID'
      },
      order: { label: 'Order' },
      status: { label: 'Status' },
      syncer: { label: 'Syncer' },
      payload: { label: 'Payload' },
      createdAt: { label: 'Created At' },
      performedAt: { label: 'Performed At' },
      errorReason: { label: 'Error Reason' },
      erpId: { label: 'ERP ID' },
    },
    export: {
      file: { name: 'exact_sync_request_export' },
    },
  },
  exactGlobeSyncRequest: {
    overview: {
      title: 'Exact Integration Last Performed At',
      empty: 'No integrations found',
      exportButton: 'Export sync requests'
    },
    field: {
      id: {
        label: 'ID'
      },
      order: { label: 'Order' },
      status: { label: 'Status' },
      syncer: { label: 'Syncer' },
      payload: { label: 'Payload' },
      createdAt: { label: 'Created At' },
      performedAt: { label: 'Performed At' },
      errorReason: { label: 'Error Reason' },
      erpId: { label: 'ERP ID' },
    },
  },
  integrationsMonitor: {
    title: 'Integrations',
    viewAll: 'View all',
    integrationLabel: 'Integration',
    status: {
      active: 'active',
      inactive: 'inactive',
      activityTimestamp: {
        active: 'Sync service active at {{timestamp}}',
        inactive: 'Sync service inactive at {{timestamp}}',
      },
    },
    syncServiceStatus: {
      label: 'Sync service status',
      lastSync: 'Last Sync',
      neverSynced: 'Never',
    },
    apiRequests: {
      usedToday: 'API requests used today:',
      almostOutOfRequests: 'Almost out of API requests',
      outOfRequests: 'Out of API requests',
      outOfRequestsHelp: 'No read or write requests can be made to Exact Online.',
    },
  },
  project: {
    overview: {
      title: 'Projects',
      empty: 'No projects that could be found.',
    },
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      description: { label: 'Description' },
      notes: { label: 'Notes' },
      startDate: { label: 'Start Date' },
      endDate: { label: 'End Date' },
    },
    filter: {
      code: 'Code',
      description: 'Description',
      startDate: 'Start Date',
      endDate: 'End Date',
    },
  },
  janKredietIntegration: {
    field: {
      apiKey: {
        label: 'Api key',
      },
      shippingLabelTemplate: {
        label: 'Shipping label template',
      },
      senderName: {
        label: 'Sender\'s name',
      },
      senderAddress: {
        label: 'Sender\'s address',
      },
      senderHouseNumber: {
        label: 'Sender\'s house number',
      },
      senderCountryCode: {
        label: 'Sender\'s country code',
      },
      senderZipCode: {
        label: 'Sender\'s postal code',
      },
      senderCity: {
        label: 'Sender\'s city',
      },
    },
  },
  upsIntegration: {
    field: {
      apiKey: {
        label: 'Api key',
      },
      shippingLabelTemplate: {
        label: 'Shipping label template',
      },
      username: {
        label: 'username'
      },
      password: {
        label: 'password'
      },
      accountNumber: {
        label: 'Account Number'
      },
      senderName: {
        label: 'Sender\'s name',
      },
      senderAddress: {
        label: 'Sender\'s address',
      },
      senderHouseNumber: {
        label: 'Sender\'s house number',
      },
      senderCountryCode: {
        label: 'Sender\'s country code',
      },
      senderZipCode: {
        label: 'Sender\'s postal code',
      },
      senderCity: {
        label: 'Sender\'s city',
      },
      senderPhoneNumber: {
        label: 'Sender\'s phone number',
      },
      userFormsEnabled: {
        label: 'Enable UPS Paperless integration',
      },
    },
  },
  transmissionIntegration: {
    field: {
      username: {
        label: 'username'
      },
      password: {
        label: 'password'
      },
      depot: {
        label: 'depot number'
      },
      shipper: {
        label: 'shipper number'
      },
      shippingLabelTemplate: {
        label: 'Shipping Label Template'
      },
    },
    shippingUnits: {
      shippingUnits: 'Shipping units',
      shippingUnit: 'Shipping unit',
      code: 'Code',
      description: 'Description',
      length: 'Length',
      width: 'Width',
      height: 'Height',
      weight: 'Weight'
    }
  },
  billOfMaterialVersion: {
    edit: {
      title: 'View Bill Of Material',
    },
    field: {
      version: {
        label: 'Version',
      },
      status: {
        label: 'Status',
      },
      description: {
        label: 'Description',
      },
      default: {
        label: 'Default',
      },
    },
    noMaterials: 'No materials in BOM'
  },
  billOfMaterialItem: {
    field: {
      articleType: { label: 'Article Type' },
      description: { label: 'Description' },
      quantity: { label: 'Quantity' },
      required: { label: '# Required' },
      assigned: { label: '# Allocated' },
      backflush: { label: 'Backflush' },
      number: { label: 'Line' },
      warehouse: { label: 'Warehouse' },
      storageLocations: { label: 'Storage Locations' },
      type: { label: 'Type' },
    },
  },
  productionOrder: {
    field: {
      billOfMaterialVersion: { label: 'BOM Version' },
      warehouse: { label: 'Warehouse' },
      status: {
        label: 'Status',
        value: {
          open: 'Open',
          released: 'Released',
          finished: 'Finished',
          draft: 'Draft'
        },
      },
      description: { label: 'Description' },
    },
    billOfMaterialVersionPlaceholder: 'No BOM available',
    procesPlaceholder: 'No make process',
    edit: {
      title: 'Edit Production Order',
    },
    create: {
      title: 'Create Production Order',
    },
  },
  script: {
    overview: {
      title: 'Scripts',
      empty: 'No scripts found.',
      addButton: 'Script',
    },
    create: {
      title: 'Add Script',
    },
    edit: {
      title: 'Edit Script',
      schedule: 'Day Schedule',
      copyPaste: {
        copyButton: 'Copy',
        pasteButton: 'Paste',
        copied: 'Copied successfully',
        pasted: 'Pasted successfully',
        pasteError: 'Nothing to paste',
        pasteTypeMismatch: 'Clipboard contains script of a different type',
      },
    },
    field: {
      type: {
        label: 'Type',
        value: {
          environment: 'Environment',
          light: 'Light',
          nutrition: 'Nutrition',
          water: 'Water',
        },
      },
      name: { label: 'Name' },
      status: {
        label: 'Status',
        value: {
          concept: 'Concept',
          test: 'Test',
          production: 'Production',
          archived: 'Archived',
        },
      },
      notes: { label: 'Notes' },
    },
  },
  environmentScript: {
    field: {
      direction: {
        label: 'Direction',
        value: {
          a_to_b: 'A to B',
          b_to_a: 'B to A',
        },
      },
      speed: { label: 'Speed' },
      tIn: { label: 'T in' },
      deltaT: { label: 'ΔT' },
      deltaX: { label: 'ΔX' },
      co2In: { label: 'CO² in' },
      startTime: { label: 'Time' },
    },
  },
  environmentScriptSchedule: {
    field: {
      direction: {
        label: 'Direction',
        value: {
          a_to_b: 'A to B',
          b_to_a: 'B to A',
        },
      },
      speed: { label: 'Speed' },
      tIn: { label: 'T in' },
      deltaT: { label: 'ΔT' },
      deltaX: { label: 'ΔX' },
      co2In: { label: 'CO² in' },
      startTime: { label: 'Time' },
    },
  },
  waterScript: {
    field: {
      nutritionScript: { label: 'Nutrition' },
      ec: { label: 'EC' },
      ecMin: { label: 'EC min' },
      ecMax: { label: 'EC max' },
      ecT: { label: 'EC t' },
      ph: { label: 'pH' },
      phMin: { label: 'pH min' },
      phMax: { label: 'pH max' },
      phT: { label: 'pH t' },
      pulse: { label: 'Pulse' },
      cycle: { label: 'Cycle' },
      flowMin: { label: 'Flow min' },
      flowMax: { label: 'Flow max' },
    },
  },
  nutritionScript: {
    field: {
      nitrogen: { label: 'Nitrogen (N)' },
      phosphorus: { label: 'Phosphorus (P)' },
      potassium: { label: 'Potassium (K)' },
      calcium: { label: 'Calcium (Ca)' },
      magnesium: { label: 'Magnesium (Mg)' },
      sulphur: { label: 'Sulphur (S)' },
    },
  },
  lightScript: {
    field: {
      intensity: { label: 'Intensity' },
      startTime: { label: 'Time' },
    },
  },
  lightScriptSchedule: {
    field: {
      intensity: { label: 'Intensity' },
      startTime: { label: 'Time' },
    },
  },
  carrierStep: {
    carrier: 'Carrier',
  },
  rampUp: {
    overview: {
      title: 'Scenarios',
      empty: 'No scenarios found.',
      addButton: 'Scenario',
    },
    create: {
      title: 'Add Scenario',
    },
    edit: {
      title: 'Edit Scenario',
      schedule: 'Harvest Schedule',
      automation: 'Automation Dates',
      noLimit: 'No Limit',
      graph: {
        label: 'Graph',
        value: {
          capacity: 'Capacity',
          operators: 'Operators',
          harvest: 'Harvest',
        },
      },
      copyPaste: {
        copyButton: 'Copy',
        pasteButton: 'Paste',
        copied: 'Copied successfully',
        pasted: 'Pasted successfully',
        pasteError: 'Nothing to paste',
      },
    },
    field: {
      name: { label: 'Name' },
      articles: {
        label: 'Articles',
        empty: 'No articles added yet',
        add: 'Add Article',
      },
      capacity: { label: 'Total Containers' },
      monday: { label: 'Monday' },
      tuesday: { label: 'Tuesday' },
      wednesday: { label: 'Wednesday' },
      thursday: { label: 'Thursday' },
      friday: { label: 'Friday' },
      saturday: { label: 'Saturday' },
      sunday: { label: 'Sunday' },
    },
  },
  rampUpTarget: {
    field: {
      date: { label: 'Date' },
      processes: { label: 'Processes' },
      capacity: { label: 'Max Capacity' },
      maxOperators: { label: 'Max Operators' },
      maxHarvest: { label: 'Max Daily Harvest' },
    },
  },
  rampUpAutomation: {
    field: {
      workStation: { label: 'Work Station' },
      date: { label: 'Automation Date' },
    },
  },
  workStationSession: {
    overview: {
      title: 'Work Station Sessions',
      empty: 'No work station sessions found.',
      viewPrintJobsButton: 'View print jobs',
    },
    field: {
      id: { label: 'ID' },
      type: {
        label: 'Type',
        value: {
          desktop: 'Desktop',
          mobile: 'Mobile',
        },
      },
      printers: {
        label: 'Printers',
        empty: 'No printers available.',
      },
      workStation: { label: 'Work Station' },
      appVersion: { label: 'App Version' },
      isActive: { label: 'Is Active' },
      createdAt: { label: 'Started At' },
      updatedAt: { label: 'Last Active At' },
      printJobs: { label: 'Print Jobs' },
    },
  },
  printJob: {
    field: {
      printer: {
        label: 'Printer',
      },
      status: {
        label: 'Status',
        value: {
          todo: 'Todo',
          done: 'Done',
          failed: 'Failed',
        },
      },
      content: {
        label: 'Content',
      },
      copies: {
        label: 'Copies',
      },
      createdAt: {
        label: 'Created At',
      },
    }
  },
  operatorAssignment: {
    field: {
      workStation: { label: 'Work Station' },
      operator: { label: 'Operator' },
      startAt: { label: 'Start At' },
      endAt: { label: 'End At' },
    },
  },
  warehouseTransfer: {
    stockWarning: {
      content: 'Warning. Some locations don\'t have enough stock for this transfer. Continue?',
    },
    overview: {
      title: 'Warehouse Transfers',
      empty: 'No Warehouse Transfers.',
      viewLinesButton: 'View Warehouse Transfer Lines',
    },
    edit: {
      title: 'Edit Warehouse Transfer',
    },
    create: {
      title: 'Add Warehouse Transfer',
    },
    field: {
      id: { label: 'ID' },
      description: { label: 'Description' },
      entryDate: { label: 'Entry Date' },
      plannedDeliveryDate: { label: 'Planned Transfer Date' },
      completedOnDate: { label: 'Completed On Date' },
      status: {
        label: 'Status',
        value: {
          draft: 'Draft',
          processed: 'Processed',
        },
      },
      source: {
        label: 'Source',
        value: {
          manual_entry: 'Manual entry',
          import: 'Import',
          transfer_advice: 'Transfer advice',
          web_service: 'Web service',
          production_request: 'Work Order'
        },
      },
      warehouseFrom: {
        label: 'Warehouse From',
      },
      warehouseTo: {
        label: 'Warehouse To',
      },
      type: {
        label: 'Transfer type',
        value: {
          true: 'Location',
          false: 'Warehouse',
        }
      },
      productionRequest: {
        label: 'Production request',
        isNull: 'Null work orders',
      },
      transferLines: {
        label: 'Warehouse Transfer Lines',
        value: 'Transfer Line {{id}}',
        empty: 'No Warehouse Transfer Lines were added.',
      },
    },
  },
  warehouseTransferLine: {
    empty: 'No Warehouse Transfer Lines.',
    create: {
      title: 'View Warehouse Transfer Lines',
    },
    field: {
      id: { label: 'ID' },
      lineNumber: { label: 'Line Number' },
      createdDate: { label: 'Created Date' },
      quantity: { label: 'Quantity' },
      articleType: { label: 'Article Type' },
      availableStock: { label: 'Available Stock' },
      noAvailableStock: { label: 'No stock availabe for the selected article type.' },
      storageLocation: {
        id: { label: 'ID' },
        warehouse: { label: 'Warehouse' },
        code: { label: 'Code' },
        stock: { label: 'Stock' },
      },
      storageLocationFrom: {
        label: 'From Storage Location',
      },
      storageLocationTo: {
        label: 'To Storage Location',
      },
      warehouseTransfer: {
        label: 'Transfer ID',
      },
    },
  },
  detailImageModal: {
    title: { label: 'Uploaded images' },
    popup: { label: 'View uploaded images' }
  },
  stockCount: {
    stockExport: {
      button: { label: 'Export warehouse stock vs batches' },
      file: { name: 'Stock vs batches' },
    },
    field: {
      warehouse: { label: 'Warehouse' },
      defaultStorageLocation: { label: 'Storage Location' },
    },
    edit: {
      title: 'Edit Stock Count'
    },
    title: { label: 'Uploaded images' },
    popup: { label: 'View uploaded images' },
    missingStockCountProcess: 'Create missing Stock Count process',
  },
  factory: {
    overview: {
      title: 'Factories',
      empty: 'No factories found.',
      addButton: 'Factory',
    },
    create: {
      title: 'Add Factory',
    },
    edit: {
      title: 'Edit Factory',
    },
    field: {
      name: { label: 'Name' },
      timezone: { label: 'Location' },
      currentTime: { label: 'Current Time' },
    },
  },
  plantationAllocation: {
    field: {
      startDate: { label: 'Start' },
      endDate: { label: 'End' },
    },
  },
  blueSkiesOverride: {
    articleType: {
      field: {
        code: { label: 'SKU' },
      },
    },
  },
  articleTypeStorageLocation: {
    field: {
      isDefault: { label: 'Default' },
      stock: { label: '# Available' },
      assigned: { label: '# Allocated' },
      warehouse: { label: 'Warehouse' },
    },
  },
  storageLocation: {
    field: {
      id: { label: 'ID' },
      code: { label: 'Code' },
      name: { label: 'Name' },
      deleted: { label: 'Deleted?' },
    },
  },
  resourceAllocation: {
    field: {
      id: { label: 'ID' },
      source: { label: 'Source' },
      target: { label: 'Target' },
      erpId: { label: 'ERP ID' },
      productionOrder: { label: 'Production Order' },
      supplier: { label: 'Supplier' },
      reference: { label: 'Reference' },
      expectedDate: { label: 'Expected Date' },
      otherSalesOrders: { label: 'Other Sales Orders' },
      quantity: { label: 'Quantity allocated' },
      orderQuantity: { label: 'Ordered quantity' },
      description: { label: 'Description' },
      producedQuantity: { label: 'Produced Quantity' },
      salesOrderLine: { label: 'Sales Order Line' },
      purchaseOrderLine: {
        label: 'POL',
        filterIsFullyReceivedErp: 'Purchase Order Line fully received ERP'
      },
    },
    overview: {
      title: 'Resource Allocations',
      empty: 'No Resource Allocations found.',
      bulkActionButton: {
        choose: 'Choose Bulk Action ({{count}})',
        delete: 'Delete Resource Allocations ({{count}})',
      },
      confirmDeleteModal: {
        title: 'Confirm delete',
        content: 'Are you sure you want to delete {{count}} Resource Allocations?',
      },
      currentAllocations: 'Current allocations',
      allocateFromFuture: 'Allocate from future stock'
    },
    statusPopup: {
      title: 'Allocation status',
      allocatedFromStock: 'Allocated from stock',
      allocatedFromExpectedStock: 'Allocated from expected stock',
      totalAllocated: 'Total allocated',
      notAllocated: 'Not allocated',
    },
  },
  navisionSalesOrder: {
    field: {
      orderDate: { label: 'Order Date' },
      requestedDeliveryDate: { label: 'Requested' },
      promisedDeliveryDate: { label: 'Promised' },
      lastDatetimeModified: { label: 'Last Modified' },
      deliveryAddress: { label: 'Address Line 1' },
      deliveryAddress2: { label: 'Address Line 2' },
      deliveryCity: { label: 'City' },
      deliveryPostcode: { label: 'Postal Code' },
      deliveryCountryRegionCode: { label: 'Region' },
      sellToAddress: { label: 'Address Line 1' },
      sellToAddress2: { label: 'Address Line 2' },
      sellToCity: { label: 'City' },
      sellToPostcode: { label: 'Postal Code' },
    },
  },
  businessRelation: {
    field: {
      name: { label: 'Customer Name' },
    },
  },
  unit4Customer: {
    field: {
      customerId: { label: 'Customer ID' }
    }
  },
  navisionCustomer: {
    field: {
      customerId: { label: 'Customer ID' },
    },
  },
  unit4Order: {
    field: {
      orderDate: { label: 'Delivery date' }
    }
  },
  salesOrderResponsibility: {
    field: {
      name: { label: 'Name' },
      company: { label: 'Company' },
      role: { label: 'Role' },
      email: { label: 'Email' },
      phoneNumber: { label: 'Phone Number' },
    },
  },
  exactAccount: {
    field: {
      number: { label: 'Exact Number' },
    },
  },
  exactSalesOrder: {
    field: {
      id: { label: 'ID' },
      number: { label: 'Number' },
      deliverAt: { label: 'Deliver At' },
      orderedAt: { label: 'Ordered At' },
      modified: { label: 'Modified At' },
    },
  },
  exactAddress: {
    field: {
      addressLine1: { label: 'Address Line 1' },
      addressLine2: { label: 'Address Line 2' },
      addressLine3: { label: 'Address Line 3' },
      city: { label: 'City' },
      postcode: { label: 'Postal Code' },
      countryName: { label: 'Country' },
    },
  },
  printModal: {
    title: 'Print {{item}}',
    selectPrinter: 'Select Printer',
    noPrintersAvailable: 'No Printers Available',
    printButton: 'Print',
    copies: 'Number of copies',
  },
  metafield: {
    overview: {
      title: 'Metafields',
      empty: 'No metafields found.',
      addButton: 'Metafield',
    },
    create: {
      title: 'Add Metafield',
    },
    edit: {
      title: 'Edit Metafield',
      new: 'New Metafield',
      noneDefined: 'No metafields defined.',
      entryLevel: {
        article_type: 'Article Type Metafields',
        load_carrier: 'Loading Carrier Metafields',
        production_request: 'Work Order Metafields',
      },
    },
    field: {
      name: { label: 'Name' },
      slug: { label: 'Slug' },
      entryLevel: {
        label: 'Entry Level',
        value: {
          article_type: 'Article Type',
          load_carrier: 'Loading Carrier',
          production_request: 'Work Order',
          classification: 'Article Group',
        },
      },
      type: {
        label: 'Type',
        value: {
          text: 'Text',
          check: 'Check',
          image: 'Image',
          choice: 'Choice',
          measure: 'Measure',
          format: 'Format',
        },
      },
      textLong: { label: 'Long' },
      choiceOptions: { label: 'Options' },
      choiceMultiple: { label: 'Multiple' },
      choiceAllowAdditions: { label: 'Allow Additions' },
      measureMin: { label: 'Minimum Value' },
      measureMax: { label: 'Maximum Value' },
      formatFormat: { label: 'Format' },
      default: { label: 'Default' },
    },
  },
  classification: {
    overview: {
      title: 'Article Groups',
      empty: 'No article groups found.',
      editTrackBatchUsage: 'Edit selected article groups',
      editModal: {
        title: 'Edit selected article groups',
        resetTrackBatchUsageInfo: 'Set "Follow group" for all Article Types within the selected groups. This will affect {{affect}} out of in total {{total}} Article Type(s).',
        setTrackBatchUsage: 'Set track batch usage for all article groups. This will affect {{affect}} article groups.',
        resetButton: 'Set "Follow group" for all Article Types',
        confirmButton: 'Update article groups',
      },
    },
    edit: {
      title: 'Edit Article Group',
      resetTrackBatchUsageButtonHelp: 'Set "Follow group" for all Article Types within this group. This will affect {{affect}} out of in total {{total}} Article Type(s).',
    },
    field: {
      name: { label: 'Name' },
      articleTypeCount: { label: 'Article Types' },
      trackBatchUsage: { label: 'Track batch usage' },
    },
  },
  nest: {
    overview: {
      title: 'Nest Types',
      empty: 'No nest types available',
      addButton: 'Add',
    },
    field: {
      nestType: {
        label: 'Nest type'
      }
    }
  },
  nestRequest: {
    label: 'Nest details',
    details: {
      thickness: 'Thickness',
      dimensions: 'Dimensions',
      runTime: 'Run time',
      material: 'Material',
    }
  },
  nestType: {
    field: {
      name: {
        label: 'Name'
      },
      articleType: { label: 'Article Type' }
    },
    create: {
      title: 'Create nest type'
    },
    edit: {
      title: 'Edit nest type'
    }
  },
  nesting: {
    title: 'Nesting',
    empty: 'Nothing to nest'
  },
  capacity: {
    overview: {
      title: 'Operator Capacity',
      empty: 'No operators available'
    },
  },
  operatorAllocation: {
    field: {
      allDay: {
        label: 'All day'
      },
      productionLine: {
        label: 'Production area'
      },
      notes: {
        label: 'Notes'
      }
    },
    dateRange: {
      label: 'Duration'
    },
    timeRange: {
      label: 'Time'
    },
    create: {
      title: 'Allocate operator to production area'
    },
    vacation: {
      title: 'Vacation',
      allDay: 'All day',
      schedule: {
        warning: 'Schedule on a non-work day?',
        confirm: 'Yes',
        cancel: 'Close'
      }
    }
  },
  materialPlan: {
    noMaterials: 'No materials in material plan',
    item: 'Material plan item',
    quantityRequired: 'Quantity',
    projected: 'Projected',
    warehouse: 'Warehouse',
    storageLocationCode: 'Storage location code',
    stock: 'Stock',
    warehouseStock: 'Other',
    message: {
      noStock: 'No stock.',
      handledByErp: 'Handled by ERP',
    }
  },
  radan: {
    export: 'Export to radan',
    import: {
      choose: 'Choose file to import from radan',
      upload: 'Upload',
      file: {
        label: 'Radan export file (.drg)'
      },
      select: 'Select files',
      validate: 'Validate files',
      someInvalid: 'Cannot upload: some selected files are invalid',
      valid: 'Valid file',
      invalid: 'Invalid file',
      needsValidation: 'Needs validation',
    }
  },
  radanIntegration: {
    uploadModal: {
      title: 'Radan Import "{{name}}"',
      chooseFiles: 'Please choose files to import.',
      trigger: 'Radan import "{{name}}"',
    },
    field: {
      symbolMetafield: {
        label: 'Symbol metafield'
      }
    }
  },
  radanIntegrationInbox: {
    retryTooltip: 'Retry import',
    field: {
      id: { label: 'ID' },
      status: {
        label: 'Status',
        value: {
          pending: 'Pending',
          processing: 'Processing',
          error: 'Error',
          success: 'Success',
        },
      },
      originalFilename: { label: 'Filename' },
      errors: { label: 'Errors' },
      nests: { label: 'Nests' },
      performances: { label: 'Performances' },
    }
  },
  planning: {
    counts: {
      open: 'ERP Ready',
      released: 'Released to shopfloor',
      flagged: 'Flagged',
      inbound: 'PU too late',
      outbound: 'SO too late',
      capacity: 'Capacity issue',
      materialPlan: 'Stock issue'
    },
    trafficLight: {
      green: 'Green',
      yellow: 'Yellow',
      red: 'Red',
      grey: 'Grey',
      none: 'Any'
    }
  },
  color: {
    red: 'Red',
    orange: 'Orange',
    yellow: 'Yellow',
    olive: 'Olive green',
    green: 'Green',
    teal: 'Turquoise',
    blue: 'Blue',
    violet: 'Violet',
    purple: 'Purple',
    pink: 'Pink',
    brown: 'Brown',
    grey: 'Gray',
    black: 'Black',
    none: 'No color',
  },
  bulkActions: {
    markColor: {
      red: '(Un)mark red',
      blue: '(Un)mark blue',
      yellow: '(Un)mark yellow',
      violet: '(Un)mark violet',
      grey: '(Un)mark grey',
      black: '(Un)mark black',
      custom: '(Un)mark {{text}}',
    },
  },
}
