import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Icon, Image, Popup, Table } from 'semantic-ui-react';
import styled from 'styled-components';
import { targetDescription } from './TargetDescription'
import Decimal from 'decimal.js'
import { t } from 'i18n';

// components
import { SerialNumber, Quantity } from 'component/TargetInfoModal/TargetDescription'
// end components

// helpers
import { humanReadable } from '../../helpers/decimal';
// end helpers

// stores
import { DetailStore } from '../../store/Detail';
import { Batch } from '../../store/Batch';
import { DetailMaterialTask } from 'store/DetailMaterialTask'
import { LoadCarrier } from '../../store/LoadCarrier';
// end stores


const DetailsTable = styled.table`
    border-spacing: 0;

    &,
    tbody,
    tr,
    th,
    td {
        padding: 0;
        margin: 0;
    }

    th {
        text-align: left;
        padding-right: 0.5em;
        color: rgba(0, 0, 0, 0.5);
    }

    th,
    td {
        vertical-align: top;
    }
`

const DetailReason = styled.span`
    margin-left: 0.8em;
    font-size: 0.7em;
    font-weight: bold;
    color: #a08040;
    position: relative;
    bottom: 0.125em;

    > i.icon {
        margin-right: 0.4em !important;
    }
`

interface DetailInfoProps {
    details: DetailStore;
    batch: Batch;
    loadCarrier: LoadCarrier | null;
    onTargetSelect: () => null
}

@observer
export default class DetailsInfo extends Component<DetailInfoProps> {
    constructor(args) {
        super(args)

        this.renderDetail = this.renderDetail.bind(this)
    }

    getBatchRequiredQuantity(materialItem, batch) {
        if (materialItem.requiredQuantity) {
            return (materialItem.requiredQuantity / batch.productionRequest.quantity) * batch.quantity
        }
        return (materialItem.quantityBatch || materialItem.quantity) * batch.quantity
    }

    renderMaterialPlanTaskField = (detail) => {
        const { batch } = this.props;

        if (detail.materials.length === 0) {
            return null
        }

        return (
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>{t('targetInfoModal.materialPlanTaskField.performed')}</Table.HeaderCell>
                        <Table.HeaderCell>{t('targetInfoModal.materialPlanTaskField.amountRequired')}</Table.HeaderCell>
                        <Table.HeaderCell />
                        <Table.HeaderCell>{t('targetInfoModal.materialPlanTaskField.description')}</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {detail.materials.map((detailMaterial) => (
                    <Table.Row>
                        <Table.Cell>{detailMaterial.value && (
                            <Icon name="check" />
                        )}</Table.Cell>
                        <Table.Cell>
                            {humanReadable(this.getBatchRequiredQuantity(detailMaterial.billOfMaterialItem, batch).toString())}
                        </Table.Cell>
                        <Table.Cell>{detailMaterial.billOfMaterialItem.articleType.getLink()}</Table.Cell>
                        <Table.Cell>{detailMaterial.billOfMaterialItem.articleType.name}</Table.Cell>
                    </Table.Row>
                ))}
            </Table>
        )

    }

    renderMaterialPlanMaterialsField = (detail) => {
        const { batch } = this.props;

        if (detail.materials.length === 0) {
            return null
        }

        return (
            <Table basic="very">
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Batches</Table.HeaderCell>
                        <Table.HeaderCell>Amount required</Table.HeaderCell>
                        <Table.HeaderCell />
                        <Table.HeaderCell>Description</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                {detail.components.map((component) => {
                    // @ts-ignore
                    const bomItem = detail.materials.models.find((item: DetailMaterialTask) => {
                        // @ts-ignore
                        return item.billOfMaterialItem.articleType.id === component.batchUsings.at(0).usedBatch.batchType.articleType.id
                    }).billOfMaterialItem
                    return (
                        <Table.Row>
                            <Table.Cell>
                                <React.Fragment key={component.cid}>
                                    <SerialNumber>{component.batchUsings.at(0).usedBatch.serialNumber}</SerialNumber>
                                    {!new Decimal(component.batchUsings.at(0).usedBatch.quantity).equals(1) && (
                                        <Quantity data-test-quantity>({humanReadable(component.batchUsings.at(0).usedBatch.quantity)})</Quantity>
                                    )}
                                    <br />
                                </React.Fragment>
                            </Table.Cell>
                            <Table.Cell>{humanReadable(this.getBatchRequiredQuantity(bomItem, batch).toString())}</Table.Cell>
                            <Table.Cell>{bomItem.articleType.getLink()}</Table.Cell>
                            <Table.Cell>{bomItem.articleType.name}</Table.Cell>
                        </Table.Row>
                    )
                })}
            </Table>
        )
    }

    renderDetail(detail) {
        const { onTargetSelect } = this.props

        const label = detail.field.label ?? detail.field.metafield.name

        let detailContent: JSX.Element | null;
        if (detail.field.type === 'check') {
            detailContent = <Icon name="check" />
        } else if (['article_type', 'bom', 'assembly_bom'].includes(detail.field.type)) {
            detailContent = detail.components.map((component) => (
                <React.Fragment key={component.cid}>
                    {targetDescription(component.batchUsings.at(0).usedBatch, onTargetSelect)}
                    <br />
                </React.Fragment>
            ))
        } else if (detail.field.type === 'material_plan_material') {
            detailContent = this.renderMaterialPlanMaterialsField(detail)
        } else if (detail.field.type === 'material_plan_task') {
            detailContent = this.renderMaterialPlanTaskField(detail)
        } else if (detail.field.type === 'image') {
            detailContent = detail.images.map((detailImage) => (
                <Popup
                    hoverable
                    position="bottom center"
                    style={{ left: '-12px' }}
                    key={detailImage.cid}
                    trigger={<Icon data-test-detail-image name="image outline" />}
                    content={<Image src={detailImage.image} />}
                />
            ))
        } else if (detail.field.type === 'storage_location') {
            detailContent = (
                <>
                    {`${detail.storageLocation.code}${detail.storageLocation.code !== '' && detail.storageLocation.name !== '' ? ' ' : ''}${detail.storageLocation.name} (${detail.storageLocation.warehouse.name})`}
                </>
            );
        } else if (detail.field.type === 'metafield') {
            if (detail.metavalue.file !== null) {
                detailContent = <img alt="" src={detail.metavalue.file} />
            } else if (Array.isArray(detail.metavalue.value)) {
                detailContent = detail.metavalue.value.join(', ')
            } else {
                detailContent = detail.metavalue.value.toString()
            }
        } else {
            detailContent = detail.value
        }

        return (
            <tr key={detail.cid} data-test-target-detail={label}>
                <th>{label}</th>
                <td>
                    {detailContent}
                    {detail.reason !== null && (
                        <DetailReason>
                            <Icon name="warning sign" />
                            {detail.reason}
                        </DetailReason>
                    )}
                </td>
            </tr>
        )
    }

    renderScannedLoadCarrier(loadCarrier: LoadCarrier) {

        return (
            <tr data-test-target-load-carrier={loadCarrier.cid}>
                <th>{t('targetInfoModal.loadCarrier.label')}</th>
                <td>
                    {loadCarrier.getLink()}
                </td>
            </tr>
        )
    }

    render() {
        const { details, loadCarrier } = this.props

        return (
            <DetailsTable data-test-target-details-table>
                <tbody>
                    {details.map(this.renderDetail)}
                    {loadCarrier && !loadCarrier.isNew ? this.renderScannedLoadCarrier(loadCarrier) : ''}
                </tbody>
            </DetailsTable>
        )
    }
}
